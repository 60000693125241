export const PROPERTY_FILTERING_I18N_CONSTANTS = {
    filteringAriaLabel: 'your choice',
    dismissAriaLabel: 'Dismiss',

    filteringPlaceholder: 'Search',
    groupValuesText: 'Values',
    groupPropertiesText: 'Properties',
    operatorsText: 'Operators',

    operationAndText: 'and',
    operationOrText: 'or',

    operatorLessText: 'Less than',
    operatorLessOrEqualText: 'Less than or equal',
    operatorGreaterText: 'Greater than',
    operatorGreaterOrEqualText: 'Greater than or equal',
    operatorContainsText: 'Contains',
    operatorDoesNotContainText: 'Does not contain',
    operatorEqualsText: 'Equals',
    operatorDoesNotEqualText: 'Does not equal',

    editTokenHeader: 'Edit filter',
    propertyText: 'Property',
    operatorText: 'Operator',
    valueText: 'Value',
    cancelActionText: 'Cancel',
    applyActionText: 'Apply',
    allPropertiesLabel: 'All properties',

    tokenLimitShowMore: 'Show more',
    tokenLimitShowFewer: 'Show fewer',
    clearFiltersText: 'Clear filters',
    removeTokenButtonAriaLabel: () => 'Remove token',
    enteredTextLabel: text => `Use: "${text}"`
};

export const FILTERING_PROPERTIES = [
    {
        propertyLabel: 'Workflow ID',
        key: 'id',
        groupValuesLabel: 'Workflow ID values',
        operators: ['=']
    },
    {
        propertyLabel: 'Requester',
        key: 'creator',
        groupValuesLabel: 'Requester values',
        operators: ['=']
    }
];
export const FILTERING_PROPERTIES_STATUS = [
    {
        propertyLabel: 'Workflow State',
        key: 'status',
        groupValuesLabel: 'Workflow State',
        operators: ['=']
    }
];

export const FILTERING_PROPERTIES_OPTIONS = [
    {
        propertyKey: 'status',
        value: "Pending"
    },
    {
        propertyKey: 'status',
        value: "Failed"
    },
    {
        propertyKey: 'status',
        value: "Cancelled"
    },
    {
        propertyKey: 'status',
        value: "Completed"
    },
    {
        propertyKey: 'status',
        value: "Timeout"
    }
];

export const getFilterWithAdditionalTitles = (additionalTitles, type) => {
    let filterProperties = FILTERING_PROPERTIES;

    //AssignedToMe page will not show additionalTitles filters
    if (type === "AssignedToMe") {
        return filterProperties
    }
    //CasesByType page will show additionalTitles filters
    let additionalTitlesFilterList = [];
    additionalTitles.forEach(header => {
        if (header != "Authorization End Date") {
            let searchKey = getFilterKeys(header)
            if (searchKey == '') {
                return
            }
            additionalTitlesFilterList.push({
                propertyLabel: header,
                key: getFilterKeys(header),
                groupValuesLabel: header,
                operators: ['=', ':']
            })
        }
    })
    let additionalTitlesWithStatus = [...FILTERING_PROPERTIES_STATUS, ...additionalTitlesFilterList]
    return [...FILTERING_PROPERTIES, ...additionalTitlesWithStatus]
}

export const getFilterKeys = (header) => {
    let filterKey = ''
    if (header == "Seller Name") {
        filterKey = "sellerCompanyName"
    } else if (header == "Product Names") {
        filterKey = "productList#productNameChinese"
    } else if (header == "Authorization End Date") {
        filterKey = "authorizationEndDate"
    } else if (header == "Payment Type") {
        filterKey = "paymentType"
    } else if (header == "Subscribe Account Id") {
        filterKey = "subscribeAccountId"
    } else if (header == "Payer Account Id") {
        filterKey = "payerAccountId"
    } else if (header == "Product Name") {
        filterKey = "productName"
    } else if (header == "Vendor Id"){
        filterKey = 'sellerVendorId'
    } else {
        filterKey = ''
    }
    return filterKey
}

export const convertToken = (token) => {
    return token.map(filter => {
        const res = {...filter};
        if (filter['propertyKey'] === "status") {
            if (filter['value'] === "Pending") {
                res['value'] = "PENDING,RUNNING"
            } else if (filter['value'] === "Failed") {
                res['value'] = "FAILED,ERROR"
            } else if (filter['value'] == "Cancelled") {
                res['value'] = "CANCELLED"
            } else if (filter['value'] == "Completed") {
                res['value'] = "SUCCEEDED"
            } else if (filter['value'] == "Timeout") {
                res['value'] = "TIMEOUT"
            } else {
                res['value'] = filter['value']
            }
        }
        return res;
    })
}