import React from 'react';
import { Table } from '@amzn/awsui-components-react';
import Header from "@amzn/awsui-components-react/polaris/header";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";

import { SingletonServiceClientBuilder } from "../APIs";
import { withRouter } from "react-router-dom";
import { HISTORY_COLUMN_DEFINITIONS, AuditHistoryEmpty } from './AuditHistoryConfig';
import PubSub from 'pubsub-js'

class AuditHistory extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            history: [],
            totalPage: 1,
            currentPageIndex: 1,
            totalCount: 0,
            pageSize: 10,
            workflowId: this.props.workflowId
        }
        this.client = SingletonServiceClientBuilder.getClient();
    }

    loadDataForGetExecutionHistoryById = (currentPageIndex) => {
        console.log("Client loaded for audit history");
        currentPageIndex = !currentPageIndex ? this.state.currentPageIndex : currentPageIndex;
        this.client.then((result) => {
            result.getExecutionHistoryById(this.state.workflowId, currentPageIndex).then((response) => {
                let data = response.data.data;
                let totalPage = response.data.totalPage;
                let currentPageIndex = response.data.currentPage;
                let totalCount = response.data.totalCount;
                let pageSize = response.data.pageSize;
                this.setState({
                    history: data,
                    isLoading: false,
                    totalPage: totalPage,
                    currentPageIndex: currentPageIndex,
                    totalCount: totalCount,
                    pageSize: pageSize
                });
            }, e => {
                this.props.setAlertMsg(e)
                this.setState({
                    isLoading: false
                })
            })
        })
    }
    componentDidMount() {
        this.loadDataForGetExecutionHistoryById()
        PubSub.subscribe('updateWorkflow', () => {
            this.loadDataForGetExecutionHistoryById()
        })
    }

    handleCurrentPageIndexChange = ({ detail }) => {
        this.setState({
            currentPageIndex: detail.currentPageIndex,
            isLoading: true
        })
        this.loadDataForGetExecutionHistoryById(detail.currentPageIndex);
    }

    render() {
        const { history, isLoading, totalPage, currentPageIndex, totalCount, pageSize } = this.state
        return (
            <Table
                id = "auditTrailTable"
                columnDefinitions={HISTORY_COLUMN_DEFINITIONS}
                items={history}
                loadingText="Loading audit trail..."
                loading={isLoading}
                variant="container"
                empty={
                    AuditHistoryEmpty
                }
                resizableColumns
                wrapLines
                header={
                    <Header
                        counter={'(' + (currentPageIndex === totalPage ? totalCount : (totalCount == 0 ? 0 : currentPageIndex * pageSize)) + '/' + totalCount + ')'}>
                        Audit Trail
                    </Header>}
                pagination={
                    <Pagination
                        pagesCount={totalPage}
                        disabled={isLoading}
                        currentPageIndex={currentPageIndex}
                        onChange={this.handleCurrentPageIndexChange}
                    />
                }
            />
        );
    }
}
export default withRouter(AuditHistory);