import React from 'react'
import Alert from '@amzn/awsui-components-react/polaris/alert'

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false, errorMessage: '', stackTrace: '' }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
      }

      componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        console.error(error, info)
        this.setState({
          errorMessage: JSON.stringify(error),
          stackTrace: JSON.stringify(info)
        })
      }

      render() {
        if (this.state.hasError) {
          // You can render any custom fallback UI
          return (
          <Alert
            dismissAriaLabel='Close alert'
            type='error'>
            <div>
              <span>Error: </span>{ this.state.errorMessage }
            </div>
            <div>
              <span>StackTrace: </span>{ this.state.stackTrace }
            </div>
          </Alert>
          )
        }
    
        return this.props.children
      }

}