import {ChoiceWidget} from "./Widget/ChoiceWidget";
import {DateWidget} from "./Widget/DateWidget";
import {DecimalWidget} from "./Widget/DecimalWidget";
import {TextAreaWidget} from "./Widget/TextAreaWidget";
import {TextWidget} from "./Widget/TextWidget";
import React from "react";
import {FileUploadWidget} from "./Widget/FileWidget";
import {widgetValidate} from "./Validate";
import {ArrayWidget} from "./Widget/ArrayWidget";
import {FormField, Input} from "@amzn/awsui-components-react";
import { ImageWidget } from "./Widget/ImageWidget";

export class Widget {
    static getWidgetByType(widget, setValue) {
        switch (widget.type) {
            case 'array':
                return (
                    <ArrayWidget key={widget.id} widget={widget} setValue={setValue}/>
                );
            case 'choice':
                return (
                    <ChoiceWidget key={widget.id} widget={widget} setValue={setValue}/>
                );
            case 'date':
                return (
                    <DateWidget key={widget.id} widget={widget} setValue={setValue}/>
                );
            case 'decimal':
                return (
                    <DecimalWidget key={widget.id} widget={widget} setValue={setValue}/>
                );
            case 'file':
                return (
                    <FileUploadWidget key={widget.id} widget={widget} setValue={setValue}/>
                );
            case 'textarea':
                return (
                    <TextAreaWidget key={widget.id} widget={widget} setValue={setValue}/>
                );
            case 'text':
                return (
                    <TextWidget key={widget.id} widget={widget} setValue={setValue}/>
                );
            case 'image':
                return (
                    <ImageWidget key={widget.id} widget={widget} setValue={setValue}/>
                );
            default:
                return (<div key={widget.id}/>);
        }
    }

    static getOperatorField(operatorList) {
        return (
            operatorList.operator != null ?
            <FormField label={'操作人'}>
                <Input
                    value={operatorList.operator}
                    readOnly={true}
                />
            </FormField> : undefined
        );
    }

    static validateWidgetValues(widgetList, buttonType) {
        return widgetList.map(widget => widgetValidate(widget, buttonType));
    }

    static containsError(widget) {
        if (widget.type === 'array') {                  // array widget contains error if
            return widget.required                      // the array widget is required but
                && (widget.value === undefined          // 1. it does not have a value, or
                    || widget.value === null
                    || widget.value.length === 0        // 2. the value is empty, or
                    || widget.value                     // 3. any of the sub-widgets contains an error
                        .flatMap(widget => widget)
                        .map(widget => this.containsError(widget))
                        .reduce((a, b) => a || b));
        } else {
            return widget.errorText !== undefined
                && widget.errorText !== null
                && String(widget.errorText).trim().length > 0;
        }
    }

    static loadWidgetsFromNode(node) {
        const readonly = node.status !== 'Active';
        return node.renderWidgetList.map(widget => {
            if (widget.type === 'array') {
                // If we are loading an array widget, the number of elements is already fixed because we do not allow
                // adding new element except when starting workflow.
                return {...widget, value: ArrayWidget.parseWidgetValue(widget, readonly),
                    readonly: widget.value!=null || readonly};
            } else {
                return {...widget, value: widget.value || '', readonly: node.status !== 'Active'};
            }
        });
    }

}
