const initialState = {
	selectedType: null,
	workflowInputs: {},
	workflowId: "",
	workflowTypes: undefined,
	workflowTypesError: undefined
}

export function WorkflowReducer(state = initialState, action) {
	switch(action.type)	 {
		case "workflowTypes":
			return {
				...state,
				workflowTypes: action.payload
			}
		case "workflowTypesError":
			return {
				...state,
				workflowTypesError: action.payload
			}
		case "workflows/workflowInputs":
			return {
				...state,
				workflowInputs: action.payload
			}
		case "workflows/selectType":
			return {
				...state,
				selectedType: action.payload
			}
		case "workflows/setWorkflowId":
			return {
				...state,
				workflowId: action.payload
			}
		default:
			return state;
	}
}