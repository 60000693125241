import React from 'react';
import { AppLayout, BreadcrumbGroup } from '@amzn/awsui-components-react';
import { FilterTable } from './Table/filter-table';
import StartWorkflowPageView from "./StartWorkflowView/StartWorkflowPageView";
import DetailPage from './DetailPage/DetailPageView';
import { useSelector } from 'react-redux'
import SideNavigation from "@amzn/awsui-components-react/polaris/side-navigation";
import store from "./store";
import './css/bpmPortal.css'

export const navItems = () => {
	let workflowTypes = store.getState().workflows.workflowTypes == undefined ? [] : store.getState().workflows.workflowTypes
	let workflowTypeList = [];
	workflowTypes.forEach(workflowType => {
		workflowTypeList.push({
			type: 'link',
			text: workflowType['name'],
			href: '/cases/list?type=' + workflowType['id']
		})
	})
	return [
		{
			type: "section",
			text: "Open Cases",
			items: [
				{ type: "link", text: "Assigned to my group", href: '/cases/assigned-to-me' }
			]
		},
		{
			type: 'section',
			text: 'View Cases by Types',
			items: workflowTypeList
		},
		{ type: 'divider' },
		{
			type: 'link',
			text: 'Report a problem',
			href: 'https://issues.cn-northwest-1.amazonaws.cn/issues/create?template=2d6665ab-2fe6-4405-902b-e7c46a9fcac8',
			external: true
		}
	];
}
export class ListWorkflowsAppLayout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			type: 'CasesByType',
			metadataId: new URLSearchParams(window.location.search).get("type")
		}
	}

	onFollowHandler(event) {
		if (!event.detail.external) {
			event.preventDefault();
			const href = event.detail.href
			this.props.history.push(href)
			this.setState({
				metadataId: new URLSearchParams(window.location.search).get("type")
			})
		}
	}
	render() {
		console.log("ListWorkflowsAppLayout")
		return (
			<AppLayout
				headerSelector="#topNav"
				navigation={
					<SideNavigation
						items={navItems()}
						onFollow={
							this.onFollowHandler.bind(this)
						}
						activeHref={"/cases/list?type=" + this.state.metadataId} />
				}
				breadcrumbs={
					<BreadcrumbGroup
						items={[
							{ text: "Marketplace BPM Portal", href: "/" },
							{ text: "All Cases", href: "/" }
						]}
						onFollow={this.onFollowHandler.bind(this)}
					/>
				}
				content={
					<FilterTable title="All Workflows" type={this.state.type} metadataId={this.state.metadataId} history={this.props.history} />
				}
				contentType="table"
				toolsHide="true"
			/>
		);
	}
}

export class WorkflowsAssignedToMeLayout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			type: 'AssignedToMe',
			activeHref: ''
		}
	}

	onFollowHandler(event) {
		if (!event.detail.external) {
			event.preventDefault();
			const href = event.detail.href
			this.props.history.push(href)
		}
	}
	render() {
		return (
			<AppLayout
			    headerSelector="#topNav"
				navigation={
					<SideNavigation
						items={navItems()}
						onFollow={
							this.onFollowHandler.bind(this)
						}
						activeHref={"/cases/assigned-to-me"}
					/>
				}
				breadcrumbs={
					<BreadcrumbGroup items={[
						{ text: "Marketplace BPM Portal", href: "/" },
						{ text: "Assigned to my group" }
					]}
					/>
				}
				content={
					<FilterTable title="Assigned to my group" type={this.state.type} history={this.props.history} />
				}
				contentType="table"
				toolsHide="true"
			/>
		);
	}
}

export class StartWorkflowAppLayout extends React.Component {
	onFollowHandler(event) {
		if (!event.detail.external) {
			event.preventDefault();
			const href = event.detail.href
			this.props.history.push(href)
		}
	}
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<AppLayout
				headerSelector="#topNav"
				navigation={
					<SideNavigation items={navItems()}
						onFollow={
							this.onFollowHandler.bind(this)
						}
						activeHref={"/start-workflow"} />
				}
				breadcrumbs={
					<BreadcrumbGroup items={[
						{ text: "Marketplace BPM Portal", href: "/" },
						{ text: "Start a Workflow", href: "/" }
					]}
					/>
				}
				content={
					<StartWorkflowPageView />
				}
				contentType="wizard"
				toolsHide="true"
			/>
		);
	}
}

export function DetailPageLayout() {
	const workflowId = useSelector((state) => state.workflows.workflowId);
	return (
		<AppLayout
			headerSelector="#topNav"
			navigation={
				<SideNavigation items={navItems()}
					activeHref={"/detail"} />
			}
			breadcrumbs={
				<BreadcrumbGroup items={[
					{ text: "Marketplace BPM Portal", href: "/" },
					{ text: workflowId, href: "/" }
				]}
				/>
			}
			content={
				<DetailPage />
			}
			toolsHide="true"
		/>
	);
}