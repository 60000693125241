import {SpaceBetween, Spinner} from "@amzn/awsui-components-react";
import * as React from "react";
import {Widget} from "../Component/Widget";
import {ProductSearchProps, SellerSearchProps, WidgetDataSearch} from "./WidgetDataSearch";

export class StartWorkflowDataForm extends React.Component {

    renderSearchBox() {
        if (this.props.workflowType === '2pProductLaunchExistingSeller') {
            return (
                <WidgetDataSearch workflowType={this.props.workflowType} searchProps={SellerSearchProps}
                                  widgetList={this.props.widgetList} setWidgetValue={this.props.setWidgetValue}
                                  setShowInputs={this.props.setShowInputs} setWidgetLocked={this.props.setWidgetLocked}
                                  setAlertMsg={this.props.setAlertMsg} setShowAlert={this.props.setShowAlert}
                                  resetWidgets={this.props.resetWidgets}/>
            );
        } else if (this.props.workflowType === 'Product_Update_Workflow') {
            return (
                <WidgetDataSearch workflowType={this.props.workflowType} searchProps={ProductSearchProps}
                                  widgetList={this.props.widgetList} setWidgetValue={this.props.setWidgetValue}
                                  setShowInputs={this.props.setShowInputs} setWidgetLocked={this.props.setWidgetLocked}
                                  setAlertMsg={this.props.setAlertMsg} setShowAlert={this.props.setShowAlert}
                                  resetWidgets={this.props.resetWidgets}/>
            );
        }
    }

    renderWidgetFields() {
        if (!this.props.showInputs) {
            return <div />;
        }
        return this.props.widgetList.map(widget => {
            return Widget.getWidgetByType(widget, this.props.setWidgetValue);
        });
    }

    render() {
        if (this.props.isLoading) {
            return (<Spinner size="big"/>);
        }
        return (
            <SpaceBetween direction="vertical" size="l">
                {this.renderSearchBox()}
                {this.renderWidgetFields()}
            </SpaceBetween>
        );

    }
}