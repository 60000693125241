const getValidationRule = (widget) => {
    const rule = widget.rule;
    return rule && JSON.parse(rule);
};

const passValidate = (widget) => {
    return widget;
}

const arrayValidate = (widget, buttonType) => {
    if (widget.type !== 'array') {
        return {...widget, errorText: `组件"${widget.id}"的类型不是"array"`};
    }

    const newValue = widget.value.map(product => product.map(widget => widgetValidate(widget, buttonType)));
    return {...widget, value: newValue};
};

const choiceValidate = (widget) => {
    if (widget.type !== 'choice') {
        return {...widget, errorText: `组件"${widget.id}"的类型不是"choice"`};
    }
    // ChoiceWidget will always have the widget.format property otherwise we would have a rendering problem,
    // thus no check required here.
    const choiceList = JSON.parse(widget.rule);
    const errorText = `允许的选项：${choiceList.join('，')}`;
    if (!choiceList.includes(widget.value)) {
        return {...widget, errorText: errorText};
    }
    return widget;
};

const dateValidate = (widget) => {
    if (widget.type !== 'date') {
        return {...widget, errorText: `组件"${widget.id}"的类型不是"date"`};
    }
    const date = new Date(widget.value);
    if (isNaN(date)) {
        return {...widget, errorText: `${widget.value}不是合法的时间格式`};
    }
    return widget;
};

const decimalValidate = (widget) => {
    if (widget.type !== 'decimal') {
        return {...widget, errorText: `组件"${widget.id}"的类型不是"decimal"`};
    }
    const rule = getValidationRule(widget);
    if (rule && rule.range) {
        const minIncl = rule.range.minIncl;
        const maxIncl = rule.range.maxIncl;
        if (minIncl !== undefined && maxIncl !== undefined && !(minIncl <= widget.value && widget.value <= maxIncl)) {
            return {...widget, errorText: `${widget.name}允许的值需在${minIncl}到${maxIncl}之间`};
        } else if (minIncl !== undefined && widget.value < minIncl) {
            return {...widget, errorText: `${widget.name}允许的值需大于等于${minIncl}`};
        } else if (maxIncl !== undefined && widget.value > maxIncl) {
            return {...widget, errorText: `${widget.name}允许的值需小于等于${minIncl}`};
        }
    }
    return widget;
};

const textValidate = (widget) => {
    if (widget.type !== 'text') {
        return {...widget, errorText: `组件"${widget.id}"的类型不是"text"`};
    }
    const format = widget.rule;
    if (format !== undefined && format.trim().length) {
        const rule = JSON.parse(format.toString());
        const reg = new RegExp(rule.js);
        if (rule.js !== undefined && !reg.test(widget.value)) {
            return {...widget, errorText: rule.desc};
        }
    }
    return widget;
};

const uuidValidate = (widget) => {
    // TODO: (zhanyl) a better way is to label sellerId as type 'uuid' and add an UUID validate in TypeValidateMap.
    if (widget.id !== 'sellerId') {
        return {...widget, errorText: `组件"${widget.id}"不适用"sellerId"的校验方法`};
    }
    if (!isValidUuid(widget.value)) {
        return {...widget, errorText: `${widget.value}不是合法的UUID格式`};
    }
    return widget;
}

const productCodeValidate = (widget) => {
    const widgetId = getCanonicalWidgetId(widget.id);
    if (widgetId !== 'productCode') {
        return {...widget, errorText: `组件"${widgetId}"不适用"productCode"的校验方法`};
    }
    if (!isValidProductCode(widget.value)) {
        return {...widget, errorText: `${widget.value}不是合法的ProductCode格式`};
    }
    return widget;
}

const TypeValidateMap = {
    array: arrayValidate,
    choice: choiceValidate,
    date: dateValidate,
    decimal: decimalValidate,
    text: textValidate,
};

const WidgetIdValidateMap = {
    sellerId: uuidValidate,
    productCode: productCodeValidate,
};

const getCanonicalWidgetId = (widgetId) => {
    return widgetId.split('-')[0];
};

export const isValidUuid = (id) => {
    return id.match(/^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i) !== null;
};

export const isValidProductCode = (code) => {
    const allowedCharset = '0123456789abcdefghijklmnopqrstuvwxyz';
    const chars = [...code.toString()];
    if (chars.filter(char => !allowedCharset.includes(char)).length > 0) {
        return false;
    }
    let base16Value;
    base16Value = chars.reduce((r, v) => r * BigInt(36) + BigInt(parseInt(v, 36)), 0n);
    const minBase16Value = 0n;                                          // 00000000-0000-0000-0000-000000000000
    const maxBase16Value = 340282366920938463463374607431768211455n;    // ffffffff-ffff-ffff-ffff-ffffffffffff
    return minBase16Value <= base16Value && base16Value <= maxBase16Value;
};

export const widgetValidate = (widget, buttonType) => {
    // All fields are required by default.
    const required = widget.required === undefined || widget.required;

    if (!required) {
        return widget;
    }

    const isEmpty = widget.value === undefined || widget.value === null || !String(widget.value).trim().length;

    if(isEmpty) {
        if(buttonType === "Save") {
            return {...widget, errorText: ``}
        } else {
            return {...widget, errorText: `${widget.name}不允许为空`};
        }
    } 

    const widgetId = getCanonicalWidgetId(widget.id);

    // Validation is performed based on granularity:
    //  1. use the specific validation logic defined for a widget ID, or
    //  2. use a common validate that applies to the widget's type, or
    //  3. pass the validate as a default
    const validator = WidgetIdValidateMap[widgetId] || TypeValidateMap[widget.type] || passValidate;
    return validator(widget, buttonType);
};