import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Autosuggest from "@amzn/awsui-components-react/polaris/autosuggest";
import * as React from "react";
import {SingletonServiceClientBuilder} from "../APIs";
import {isValidProductCode, isValidUuid} from "../Component/Validate";

export const SellerSearchProps = {
    label: "Search by Seller ID",
    placeholder: "d86d613e-d4e3-4746-8dff-baec467169e7",
    invalidFormatMessage: "Invalid seller ID, expected an UUID",
    loadingText: "Loading seller information...",
    description: "An UUID is required",
    api: 'getSellerInfoBySellerId',
    inputType: 'Seller ID',
    labeForSearchByCompanyName: "Search by Seller Company Name (Legal Name)",
    placeholderForSearchByCompanyName: "宁夏西云数据科技有限公司",
    loadingTextForSearchByCompanyName: "Loading seller information...",
    descriptionForSearchByCompanyName: "A valid seller company name is required, please press 'ENTER' to confirm search input.",
    apiForSearchByCompanyName: 'getSellerIdBySellerName',
    inputTypeForSearchByCompanyName: 'Seller Company Name (Legal Name)',
};

export const ProductSearchProps = {
    label: "Search by MP ProductCode",
    placeholder: "7m04idlbhs695grrytee5k1d0",
    invalidFormatMessage: "Invalid MP ProductCode",
    loadingText: "Loading product information...",
    description: "A valid MP ProductCode is required",
    api: 'getProductInfoByProductCode',
    inputType: 'MP ProductCode',
};

export class WidgetDataSearch extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            searchTerm: '',
            searchTermForSearchByCompanyName: '',
            errorText: '',
            errorTextForSearchByCompanyName: '',
            constraintText: '',
            constraintTextForSearchByCompanyName: '',
            invalid: false,
            invalidForSearchByCompanyName: false,
            disabled: false,
            disabledForSearchByCompanyName: false,
            statusType: 'auto',
            statusTypeForSearchByCompanyName: 'auto',
        };
        this.props.setShowInputs(false);
        this.props.setShowAlert(false);
        this.client = SingletonServiceClientBuilder.getClient();
    }

    fulfilledSellerInfo = (internalSellerId, isSearchById) => {
        this.client.then(client => client.getSellerInfoBySellerId("Product_Publish_Workflow", internalSellerId).then(
            result => {
                Object.keys(result.data).forEach(widgetId => {
                    if (result.data[widgetId] == null || result.data[widgetId] == "" || result.data[widgetId] == undefined) {
                        return;
                    }
                    this.props.setWidgetValue(widgetId, result.data[widgetId], true);
                });
                if (isSearchById) {
                    const constraintText = `Seller information has been filled in the form for internalSellerId ${internalSellerId}.`;
                    this.setSearchCompleteSuccessState(constraintText);
                }
            }, e => {
                console.log(e);
                this.setSearchCompleteFailureState(e);
            }
        ));
    }

    fulfilledSellerInfoBySellerName = (sellerCompanyName) => {
        this.client.then(client => client.getSellerIdBySellerName("Product_Publish_Workflow", sellerCompanyName).then(
            result => {
                Object.keys(result.data).forEach(widgetId => {
                    if (result.data[widgetId] == null || result.data[widgetId] == "" || result.data[widgetId] == undefined) {
                        return;
                    }
                    this.props.setWidgetValue(widgetId, result.data[widgetId], true);
                });
                const constraintText = `Seller information has been filled in the form for seller company name ${sellerCompanyName}.`;
                this.setSearchCompleteSuccessState(constraintText);
            }, e => {
                console.log(e);
                this.setSearchCompleteFailureStateForSearchByCompanyName(e);
            }
        ));
    }

    fulfilledProductInfo = (productCode) => {
        this.client.then(client => client.getProductInfoByProductCode(productCode).then(
            result => {
                console.log(result.data);
                Object.keys(result.data).forEach(widgetId => {
                    let value = result.data[widgetId];
                    if (widgetId === 'authorizationStartDate' || widgetId === 'authorizationEndDate') {
                        // TODO (zhanyl): change getProductInfo API in service to return ISO8601 date strings instead of
                        //      instants as authorization start/end date.
                        const date = new Date(0);
                        date.setUTCSeconds(value);
                        value = date.toISOString().split('T')[0];
                    }
                    // TODO (zhanyl): consider passing this information back from service rather than hard coding it here.
                    let mutableWidgetIds = ['sellerTaxRate', 'listingFeeRate', 'authorizationStartDate', 'authorizationEndDate'];
                    this.props.setWidgetValue(widgetId, value, !mutableWidgetIds.includes(widgetId));

                });
                const constraintText = `Product information with productCode ${productCode} fills in the form.`;
                this.setSearchCompleteSuccessState(constraintText);
            }, e => {
                console.log(e);
                this.setSearchCompleteFailureState(e);
            }
        ));
    }

    setSearchEmptyState() {
        this.setState({
            errorText: '',
            constraintText: '',
            constraintTextForSearchByCompanyName: '',
            invalid: false,
            disabled: false,
            statusType: 'auto',
            searchTermForSearchByCompanyName: ''
        });
        this.props.setShowAlert(false);
        this.props.setShowInputs(false);
        this.props.resetWidgets();
    }

    setSearchEmptyStateForSearchByCompanyName() {
        this.setState({
            errorTextForSearchByCompanyName: '',
            constraintText: '',
            constraintTextForSearchByCompanyName: '',
            invalidForSearchByCompanyName: false,
            disabledForSearchByCompanyName: false,
            statusTypeForSearchByCompanyName: 'auto',
            searchTerm: ''
        });
        this.props.setShowAlert(false);
        this.props.setShowInputs(false);
        this.props.resetWidgets();
    }

    setSearchInvalidState() {
        this.setState({
            errorText: this.props.searchProps.invalidFormatMessage,
            constraintText: '',
            invalid: true,
            disabled: false,
            statusType: 'auto',
        });
        this.props.setShowAlert(false);
        this.props.setShowInputs(false);
        this.props.resetWidgets();
    }

    setSearchInProgressState() {
        this.setState({
            errorText: '',
            constraintText: '',
            invalid: false,
            disabled: true,
            statusType: 'auto',
        });
        this.props.setShowAlert(false);
        this.props.setShowInputs(false);
    }

    setSearchInProgressStateForSearchByCompanyName() {
        this.setState({
            errorTextForSearchByCompanyName: '',
            constraintTextForSearchByCompanyName: '',
            invalidForSearchByCompanyName: false,
            disabledForSearchByCompanyName: false,
            statusTypeForSearchByCompanyName: 'loading',
        });
        this.props.setShowAlert(false);
        this.props.setShowInputs(false);
    }

    setSearchCompleteSuccessState(constraintText) {
        this.setState({
            searchNameForSearchByCompanyName: '',
            errorText: '',
            constraintText: constraintText,
            constraintTextForSearchByCompanyName: '',
            invalid: false,
            disabled: false,
            statusType: 'auto',
        });
        this.props.setShowAlert(false);
        this.props.setShowInputs(true);
    }


    setSearchCompleteSuccessStateForSearchByCompanyName(constraintTextForSearchByCompanyName, sellerCompanyName, sellerId) {
        this.setState({
            errorTextForSearchByCompanyName: '',
            constraintText: '',
            constraintTextForSearchByCompanyName: constraintTextForSearchByCompanyName,
            invalidForSearchByCompanyName: false,
            disabledForSearchByCompanyName: false,
            statusTypeForSearchByCompanyName: 'finished',
        });
        this.props.setShowAlert(false);
        this.props.setShowInputs(true);
        // this.fulfilledSellerInfo(sellerId, false);
    }

    setSearchCompleteFailureState(error) {
        this.setState({
            errorText: '',
            invalid: true,
            disabled: false,
            statusType: 'auto',
        });
        this.props.setShowAlert(false);
        this.props.setShowInputs(false);
        this.props.setAlertMsg(error, this.props.searchProps.api);
    }

    setSearchCompleteFailureStateForSearchByCompanyName(error) {
        this.setState({
            errorTextForSearchByCompanyName: '',
            invalidForSearchByCompanyName: true,
            disabledForSearchByCompanyName: false,
            statusTypeForSearchByCompanyName: 'auto',
        });
        this.props.setShowAlert(false);
        this.props.setShowInputs(false);
        this.props.setAlertMsg(error, this.props.searchProps.apiForSearchByCompanyName);
    }

    onSearch = ({detail}) => {
        let input = detail.value;
        this.setState({
            searchTerm: input,
            searchTermForSearchByCompanyName: ''
        });
        if (input === '') {
            this.setSearchEmptyState();
            return;
        }
        if (this.props.workflowType === "Product_Update_Workflow") {
            if (!isValidProductCode(input)) {
                this.setSearchInvalidState();
            } else {
                this.setSearchInProgressState();
                this.fulfilledProductInfo(input);
            }
        } else {
            if (!isValidUuid(input)) {
                this.setSearchInvalidState();
            } else {
                this.setSearchInProgressState();
                this.fulfilledSellerInfo(input, true);
            }
        }
    }

    //Set input value
    setInputValue = ({detail}) => {
        let input = detail.value;
        this.setState({
            searchTermForSearchByCompanyName: input,
            searchTerm: ''
        });
        if (input === '') {
            this.setSearchEmptyStateForSearchByCompanyName();
            return;
        }
    }

    // Trigger search when user press ENTER
    onSearchByCompanyName = ({detail}) => {
        let input = detail.key;
        if (input === 'Enter') {
            this.setSearchInProgressStateForSearchByCompanyName();
            this.fulfilledSellerInfoBySellerName(this.state.searchTermForSearchByCompanyName);
        }
    };

    render() {
        return (
            <div>
                <FormField
                    label={this.props.searchProps.labeForSearchByCompanyName}
                    description={this.props.searchProps.descriptionForSearchByCompanyName}
                    errorText={this.state.errorTextForSearchByCompanyName}
                    constraintText={this.state.constraintTextForSearchByCompanyName}
                >
                    <Autosuggest
                        placeholder={this.props.searchProps.placeholderForSearchByCompanyName}
                        onChange={this.setInputValue}
                        onKeyDown={this.onSearchByCompanyName}
                        value={this.state.searchTermForSearchByCompanyName}
                        enteredTextLabel={value => `${this.props.searchProps.inputTypeForSearchByCompanyName}: "${value}". Please press 'ENTER' to confirm.`}
                        invalid={this.state.invalidForSearchByCompanyName}
                        disabled={this.state.disabledForSearchByCompanyName}
                        errorText={this.state.errorTextForSearchByCompanyName}
                        loadingText={this.props.searchProps.loadingTextForSearchByCompanyName}
                        statusType={this.state.statusTypeForSearchByCompanyName}
                        filteringType="manual"
                        empty="No matches found"
                    />
                </FormField>
                <FormField
                    label={this.props.searchProps.label}
                    description={this.props.searchProps.description}
                    errorText={this.state.errorText}
                    constraintText={this.state.constraintText}
                >
                    <Autosuggest
                        placeholder={this.props.searchProps.placeholder}
                        onChange={this.onSearch}
                        value={this.state.searchTerm}
                        enteredTextLabel={value => `${this.props.searchProps.inputType}: "${value}"`}
                        invalid={this.state.invalid}
                        disabled={this.state.disabled}
                        errorText={this.props.searchProps.errorText}
                        loadingText={this.props.searchProps.loadingText}
                        statusType={this.state.statusType}
                        empty="No matches found"
                    />
                </FormField>
            </div>
        );
    }
}