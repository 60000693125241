import React from 'react';
import Table from '@amzn/awsui-components-react/polaris/table';
import { getColumnWithAdditionalHeader } from './table-config';
import ProperttyFilter from '@amzn/awsui-components-react/polaris/property-filter';
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import { Header, ButtonDropdown, Button, SpaceBetween, Alert } from '@amzn/awsui-components-react';
import { TableNoMatchState } from './common-components';
import { SingletonServiceClientBuilder } from '../APIs'
import {EXPORT_FAIL_HEADER, EXPORT_FAIL_TIP, EXPORT_SUCCESS_HEADER,EXPORT_SUCCESS_TIP,INTERNAL_ERROR,INTERNAL_ERROR_TIP,NOT_FOUND,NOT_FOUND_TIP,UNAUTHORIZED,UNAUTHORIZED_TIP} from '../common/strings'
import store from "../store"
import PubSub from 'pubsub-js'

import {
  PROPERTY_FILTERING_I18N_CONSTANTS,
  FILTERING_PROPERTIES_OPTIONS,
  getFilterWithAdditionalTitles,
  convertToken
} from './table-property-filter-config';

export class FilterTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      type: props.type,
      metadataId: new URLSearchParams(window.location.search).get("type"),
      items: [],
      filteringQuery: { tokens: [], operation: 'and' },
      isLoading: true,
      additionalTitles: [],
      totalPage: 1,
      currentPage: 1,
      totalCount: 0,
      pageSize: 10,
      isSubmitting: false,
      alertMsg: {
        visible: false,
        type: 'error',
        header: '',
        tip: ''
      },
      enableCreate: false,
      enableExport: false,
      workflowTypesError: store.getState().workflows.workflowTypesError
    }
    store.subscribe(() => {
      this.setState({
        workflowTypesError: store.getState().workflows.workflowTypesError
      });
    });
    this.client = SingletonServiceClientBuilder.getClient();
  }
  onFollowHandler = (event) => {
    const ref = "/start-workflow?type=" + this.props.metadataId;
    if (event.detail == null || !event.detail.external) {
      event.preventDefault();
      const href = event.detail == null ? ref : event.detail.href
      this.props.history.push(href)
    }
  }

  renderActions(type) {
    const defaultUrl = "/start-workflow?type=" + type;
    let enableCreate = this.state.enableCreate
    let enableExport = this.state.enableExport
    if (!type) {
      return
    }
    else if (type === "Product_Publish_Workflow") {
      return (
        <SpaceBetween direction="horizontal" size="s">
          <Button href="https://w.amazon.com/bin/view/AWS_Marketplace_CN_MCO/Seller_registration_process/BPM_Create_workflow_SOP/" variant="link">
            查看操作指南
          </Button>
          {enableExport ?
            <Button
              iconName="download"
              loading={this.state.isSubmitting}
              onClick={this.exportFile}
            >
              Export launched products
            </Button>
            : ""}
          {enableCreate ?
            <ButtonDropdown
              items={[
                { text: "for New Seller", id: "2pProductLaunchForNewSeller", href: "/start-workflow?type=2pProductLaunchNewSeller" },
                { text: "for Existing Seller", id: "2pProductLaunchForExistingSeller", href: "/start-workflow?type=2pProductLaunchExistingSeller" }
              ]}
              onItemFollow={this.onFollowHandler.bind(this)}
              variant="primary"
            >
              New workflow
            </ButtonDropdown>
            : ""}
        </SpaceBetween>
      )
    } else {
      return (
        enableCreate ?
          <Button
            href={defaultUrl}
            onFollow={this.onFollowHandler.bind(this)}
            variant="primary"
          >
            New workflow
          </Button>
          : ""
      )
    }
  }

  handleClearFilter = () => {
    const DEFAULT_FILTERING_QUERY = { tokens: [], operation: 'and' };
    this.setState({
      filteringQuery: DEFAULT_FILTERING_QUERY
    })
    this.loadDataForGetExecutionList(DEFAULT_FILTERING_QUERY, 1)
  };
  //when add filterInfo, currentPageIndex will set to 1
  handlePropertyFilteringChange = ({ detail }) => {
    this.loadDataForGetExecutionList(detail, 1);
    this.setState({
      filteringQuery: detail
    })
  }

  handleCurrentPageIndexChange = ({ detail }) => {
    //when change page, it also takes filterinfo in request
    this.loadDataForGetExecutionList(this.state.filteringQuery, detail.currentPageIndex);
    this.setState({
      currentPage: detail.currentPageIndex,
      isLoading: true
    })
  }

  exportFile = () => {
    this.client.then((result) => {
      result.exportFile("Product_Publish_Workflow").then((response) => {
        this.setState({ isSubmitting: true })
        window.open(response.data)
        this.setState({
          isSubmitting: false,
          alertMsg: {
            visible: true,
            type: 'success',
            header: EXPORT_SUCCESS_HEADER,
            tip: EXPORT_SUCCESS_TIP
          }
        })
      }, e => {
        console.log(e)
        this.setState({
          isSubmitting: false
        })
        this.renderAlertMsg(e, "exportFile")
      });
    })
  }

  loadDataForGetExecutionList = (detail, currentPageIndex) => {
    console.log("loadDataForGetExecutionList")
    // when changing href in link, need to reset state
    this.setState({isLoading: true, alertMsg:{visible:false}})
    const token = detail == undefined ? [] : detail.tokens
    //convert token to actual status
    const tokenForSearch = convertToken(token)
    let encodedFilterInfo = encodeURIComponent(JSON.stringify(tokenForSearch))
    this.client.then((result) => {
      result.getExecutionList(this.state.type, this.props.metadataId, currentPageIndex, encodedFilterInfo).then((response) => {
        if(this.state.workflowTypesError != undefined) {
          this.renderAlertMsg(this.state.workflowTypesError, "updateWorkflowError")
        }
        let data = response.data.dataList.data;
        let additionalTitles = response.data.additionalTitles;
        let totalPage = response.data.dataList.totalPage;
        let currentPage = response.data.dataList.currentPage;
        let totalCount = response.data.dataList.totalCount;
        let pageSize = response.data.dataList.pageSize;
        let enableCreate = response.data.enableCreate
        let enableExport = response.data.enableExport
        this.setState({
          items: data,
          isLoading: false,
          additionalTitles: additionalTitles,
          totalPage: totalPage,
          currentPage: currentPage,
          totalCount: totalCount,
          pageSize: pageSize,
          enableCreate,
          enableExport
        })
      }, e => {
        this.setState({
          isLoading: false
        })
        this.renderAlertMsg(e, "getExecutionList")
      })
    })
  }

  renderAlertMsg = (e, APIType) => {
    if (e.response) {
      if (e.response.status == 404) {
        this.setState({
          alertMsg: {
            visible: true,
            type: 'error',
            header: APIType == "exportFile" ? EXPORT_FAIL_HEADER : NOT_FOUND,
            tip: APIType == "exportFile" ? EXPORT_FAIL_TIP : NOT_FOUND_TIP
          }
        })
      } else if (e.response.status == 403) {
        this.setState({
          alertMsg: {
            visible: true,
            type: 'error',
            header: UNAUTHORIZED,
            tip: UNAUTHORIZED_TIP
          }
        })
      } else {
        // handle error including status > 500 and 400
        this.setState({
          alertMsg: {
            visible: true,
            type: 'error',
            header: INTERNAL_ERROR,
            tip: INTERNAL_ERROR_TIP
          }
        })
      }
    } else {
      this.setState({
        alertMsg: {
          visible: true,
          type: 'error',
          header: e.message,
          tip: e.stack
        }
      })
    }
  }

  componentDidMount() {
    // avoid setstate in render which leads to multiple render
    console.log("call GetExecutionList")
    this.loadDataForGetExecutionList()
    PubSub.subscribe('updateWorkflowError', (_, e) => {
      console.log(e)
      this.state.alertMsg.visible ? "" :
        this.renderAlertMsg(e, "updateWorkflowError")
    })
  }

  componentDidUpdate(prevProps) {
    // when metadataId change from browser, force calling getExecutionList and updating state
    if (this.props.metadataId != prevProps.metadataId) {
      this.loadDataForGetExecutionList();
    }
  }

  alertRender(){
    const {alertMsg} = this.state
    return (
      <Alert
          visible={alertMsg.visible}
          dismissAriaLabel="Close alert"
          type={alertMsg.type}
          header={alertMsg.header}
        >
          {alertMsg.tip}
        </Alert>
    )
  }

  renderTable() {
    const { title, items, additionalTitles, type, isLoading, filteringQuery, totalPage, currentPage, totalCount, pageSize, alertMsg } = this.state
    const { metadataId, history } = this.props
    let workflows = items == null ? [] : items;
    const handleSortingChange = event => {
      setSortingDescending(event.detail.isDescending);
      setSortingColumn(event.detail.sortingColumn);
    };
    return (
      alertMsg.visible && alertMsg.type == 'error' ? "" :
        <Table
          columnDefinitions={getColumnWithAdditionalHeader(additionalTitles, type, history)}
          items={workflows}
          loadingText="Loading workflows"
          loading={isLoading}
          onSortingChange={handleSortingChange}
          empty={<TableNoMatchState onClearFilter={this.handleClearFilter} />}
          header={
            <Header
              counter={'(' + (currentPage === totalPage ? totalCount : (totalCount == 0 ? 0 : currentPage * pageSize)) + '/' + totalCount + ')'}
              actions={this.renderActions(metadataId)}
            >
              {title}
            </Header>
          }
          variant="full-page"
          filter={
            <ProperttyFilter
              i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
              filteringProperties={getFilterWithAdditionalTitles(additionalTitles, type)}
              filteringOptions={FILTERING_PROPERTIES_OPTIONS}
              hideOperations='true'
              expandToViewport='true'
              query={filteringQuery}
              onChange={this.handlePropertyFilteringChange}
            />
          }
          pagination={
            <Pagination
              pagesCount={totalPage}
              disabled={isLoading}
              currentPageIndex={currentPage}
              onChange={this.handleCurrentPageIndexChange}
            />
          }
        />
    )
  }

  render() {
    return (
      <SpaceBetween direction="vertical" size="l">
        {this.alertRender()}
        {this.renderTable()}
      </SpaceBetween>
    );
  }
}