import {Button, Container, FormField, Header, SpaceBetween} from "@amzn/awsui-components-react";
import React from "react";
import {Widget} from "../Widget";

export class ArrayWidget extends React.Component {
    constructor(props) {
        super(props);
        this.getWidgetValue = this.getWidgetValue.bind(this);
        this.setWidgetValue = this.setWidgetValue.bind(this);
        this.productWidget = this.productWidget.bind(this);
        this.addElement = this.addElement.bind(this);
        this.removeElement = this.removeElement.bind(this);
    }

    generateRandomHexString(size) {
        return [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
    }

    deepCopy(obj, randomSuffix) {
        if (obj instanceof Array) {
            let sourceCopy = obj instanceof Array ? [] : {};
            for (const item in obj) {
                sourceCopy[item] = this.deepCopy(obj[item], randomSuffix)
            }
            return sourceCopy;
        }
        let newObj = {};
        for (const attr in obj) {
            if (attr === "id") {
                newObj[attr] = obj[attr] + "-" + randomSuffix;
            } else {
                newObj[attr] = obj[attr];
            }
        }
        return newObj;
    }

    productWidgetData() {
        const randomSuffix = this.generateRandomHexString(6);
        return this.deepCopy(this.props.widget["renderList"], randomSuffix)
    }

    getWidgetValue(widgetId) {
        let value = "";
        this.props.widget.value.forEach(product => {
            product.forEach(widget => {
                if (widget.id === widgetId) {
                    value = widget.value;
                }
            });
        });
        return value;
    }

    setWidgetValue(widgetId, value) {
        const newProducts = this.props.widget.value.map(product => {
            return product.map(widget => {
                return widget.id === widgetId ? {...widget, value: value, errorText: null} : widget;
            });
        });
        this.props.setValue(this.props.widget.id, newProducts);
    }

    static parseWidgetValue(widget, readonly) {
        let value = JSON.parse(widget.value) || [];

        // Toggle readonly on sub-widgets based on whether current node is active
        value = value.map(product => product.map(subWidget => {
            return {...subWidget, readonly: readonly, parentId: widget.id};
        }));

        // Filter out widgets that should not show up
        if (!widget.renderList) {
            return value;
        }
        value = value.map(product => {
            const suffix = product[0].id.split('-').pop();
            return widget.renderList.map(subWidget => {
                const id = `${subWidget.id}-${suffix}`;
                const subWidgetValue = value.flatMap(w => w).filter(w => w.id === id)[0]?.value || '';
                return {...subWidget, id: id, value: subWidgetValue};
            });
        });
        return value;
    }

    static mergeWithPreviousWidgetValue(prevValue, curValue) {
        prevValue = prevValue == null ? [] : prevValue;
        // For each product, take
        //  1. all widgets from curValue (updating widget)
        //  2. all widgets from prevValue that are not in curValue (always return complete copy of submitted values)
        return prevValue.map((prevProduct, index) => {
            const widgetList = [...prevProduct];
            curValue[index].forEach(curWidget => {
                const prevWidget = widgetList.find(prevWidget => prevWidget.id === curWidget.id);
                if (prevWidget === undefined) {
                    widgetList.push(curWidget);
                } else {
                    prevWidget.value = curWidget.value;
                }
            });
            return widgetList;
        });
    }

    addElement() {
        const {widget, setValue} = this.props;
        const value = widget.value || [];
        setValue(widget.id, [
            ...value,
            this.productWidgetData(value.length + 1),
        ]);
    }

    removeElement(index) {
        const {widget, setValue} = this.props;
        setValue(widget.id, widget.value.filter((product, i) => i !== index));
    }

    productWidget(product, index) {
        const showIndex = index + 1;
        const productWidgets = product.map(widget => {
            return Widget.getWidgetByType(widget, this.setWidgetValue);
        });

        return (
            <Container key={showIndex} header={
                <Header variant="h4" actions={
                    <Button onClick={e => {
                        e.preventDefault();
                        this.removeElement(index)
                    }} disabled={this.props.widget.readonly}>
                        Delete
                    </Button>
                }>
                    {this.props.widget.name} {showIndex}
                </Header>
            }>
                <SpaceBetween size="xs">
                    {productWidgets}
                </SpaceBetween>
            </Container>
        );
    }

    render() {
        const products = this.props.widget.value || [];
        const widgetList = products.map(this.productWidget);
        const button = !this.props.widget.readonly ?
            <Button onClick={e => {
                e.preventDefault();
                this.addElement()
            }}>
                Add
            </Button>
            : null;

        return (
            <FormField label={this.props.widget.name} errorText={this.props.widget.errorText}>
                <SpaceBetween size='s'>
                    {widgetList}
                    {button}
                </SpaceBetween>
            </FormField>
        );
    }
}