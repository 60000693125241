import React from 'react';
import {
    StatusIndicator
} from '@amzn/awsui-components-react';

//states are PENDING, RUNNING, CANCELLED, SUCCEEDED, ERROR, FAILED
// state: SUCCEEDED, show: Completed
// state: ERROR, FAILED, show: Failed
// state: CANCELLED, show: Cancelled
// state: RUNNING, PENDING, show: Pending
// state: TIMEOUT, show: Timeout
export const renderStatusField = (status) => {
    if (status === 'SUCCEEDED') {
        return (<StatusIndicator type="success">Completed</StatusIndicator>);
    } else if (status === 'FAILED' || status === 'ERROR') {
        return (<StatusIndicator type="error">Failed</StatusIndicator>);
    } else if (status === 'CANCELLED') {
        return (<StatusIndicator type="stopped">Cancelled</StatusIndicator>);
    } else if (status === 'RUNNING' || status === 'PENDING'){
        return (<StatusIndicator type="pending">Pending</StatusIndicator>);
    } else if (status === 'TIMEOUT') {
        return (<StatusIndicator type="warning">Timeout</StatusIndicator>);
    }
    else {
        return status; 
    }
}
