import * as React from "react";
import {Spinner} from "@amzn/awsui-components-react";
import {withRouter} from "react-router-dom";
import {
    SpaceBetween, Alert
} from "@amzn/awsui-components-react";
import {SingletonServiceClientBuilder} from '../APIs'
import BasicDetails from "./BasicDetails";
import AuditHistory from "./AuditHistory";
import {InteractiveWorkflow} from "./InteractiveWorkflow";
import {INTERNAL_ERROR,INTERNAL_ERROR_TIP,UNAUTHORIZED,UNAUTHORIZED_TIP,ID_NOT_EXIST, ID_NOT_EXIST_TIP}  from '../common/strings';
import store from "../store";
import PubSub from 'pubsub-js'

class DetailPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // get path information from react-router
            workflowId: this.props.match.params.workflowId,
            details: [],
            isLoading: true,
            alertMsg: {
                visible: false,
                type: 'error',
                header: '',
                tip: ''
              },
              workflowTypesError: store.getState().workflows.workflowTypesError
        };
        store.subscribe(() => {
            this.setState({
              workflowTypesError: store.getState().workflows.workflowTypesError
            });
          });
        this.client = SingletonServiceClientBuilder.getClient();
    }

    componentDidMount(){
        this.loadDataForGetWorkflowDetailsById()
        PubSub.subscribe('updateWorkflow', () => {
            this.loadDataForGetWorkflowDetailsById()
        })
        PubSub.subscribe('updateWorkflowError',(_,e) => {
          this.state.alertMsg.visible ? "" :  
          this.setAlertMsg(e);
        })
    }

  loadDataForGetWorkflowDetailsById = () => {
    console.log("Client loaded");
    this.client.then((result) => {
      result.getWorkflowDetailsById(this.state.workflowId).then((response) => {
        if (this.state.workflowTypesError != undefined) {
          this.setAlertMsg(this.state.workflowTypesError)
        } else {
          let data = response.data;
          this.setState({
            details: data,
            isLoading: false
          });
        }
      }, e => {
        this.setAlertMsg(e)
        this.setState({
          isLoading: false,
        });
      });
    })
  }

    alertRender(){
        const {alertMsg} = this.state
        return (
          <Alert
              visible={alertMsg.visible}
              type={alertMsg.type}
              header={alertMsg.header}
            >
              {alertMsg.tip}
            </Alert>
        )
      }
      setAlertMsg = (e) => {
        if(e.response) {
            if(e.response.status == 404) {
              this.setState({
                alertMsg: {
                  visible: true,
                  type: 'error',
                  header: ID_NOT_EXIST,
                  tip: ID_NOT_EXIST_TIP
                }
              })
            } else if (e.response.status == 403) {
              this.setState({
                alertMsg: {
                  visible: true,
                  type: 'error',
                  header: UNAUTHORIZED,
                  tip: UNAUTHORIZED_TIP
                }
              })
            } else {
              // handle error including status > 500 and 400
              this.setState({
                alertMsg: {
                  visible: true,
                  type: 'error',
                  header: INTERNAL_ERROR,
                  tip: INTERNAL_ERROR_TIP
                }
              })
            }
          } else {
            this.setState({
                alertMsg: {
                visible: true,
                type: 'error',
                header: e.message,
                tip: e.stack
              }
            })
          }
      }

      setSuccessMsg = (node,tip) => {
        this.setState({
            alertMsg: {
            visible: true,
            type: 'success',
            header: node,
            tip: tip
          }
        })
      }

  renderDetails = () => {
    return (
      this.state.isLoading ? <Spinner size="big" /> :
        this.state.details.length === 0 ? "" :
          <SpaceBetween size="l">
            <BasicDetails details={this.state.details} client={this.client} setAlertMsg={this.setAlertMsg} setSuccessMsg={this.setSuccessMsg} />
            <InteractiveWorkflow details={this.state.details} setAlertMsg={this.setAlertMsg} setSuccessMsg={this.setSuccessMsg} />
            <AuditHistory workflowId={this.state.workflowId} setAlertMsg={this.setAlertMsg} />
          </SpaceBetween>
    )
  }

  render() {
    return (
      <SpaceBetween size="l">
        {this.alertRender()}
        {this.renderDetails()}
      </SpaceBetween>
    );
  }
}

export default withRouter(DetailPage);
