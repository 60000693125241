import BONESMidwayJavaScriptClient from 'BONESMidwayJavaScriptClient';

export const uploadObjectUsingPresignedURL = (file, presignedUrl) => {
    return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.open('PUT', presignedUrl, true);
        xhr.onload = () => {
            if (xhr.status === 200) {
                console.log('Uploaded data successfully');
                resolve('Uploaded data successfully'); // Resolve the promise
            } else {
                reject(`Upload failed with status: ${xhr.status}`); // Reject if status is not 200
            }
        };
        xhr.onerror = () => {
            console.log('Nope')
            reject('Upload failed.'); // Reject the promise
        };
        xhr.send(file);
    });
}

export class SingletonServiceClientBuilder {
    static jsclient

    static getClient() {
        if (SingletonServiceClientBuilder.jsclient === undefined) {
            console.log("create client")
            return new Promise((resolve) => {
                new JSClient({
                    onReady: (client) => {
                        SingletonServiceClientBuilder.jsclient = client
                        resolve(client)
                    }
                })
            })
        }
        return Promise.resolve(SingletonServiceClientBuilder.jsclient)
    }
}

export class JSClient {
    constructor(props) {
        this.props = props || {};
        this.clientLoaded = this.clientLoaded.bind(this);
        new BONESMidwayJavaScriptClient({ onReady: this.clientLoaded, onErr: this.clientErrored });
    }

    clientLoaded(client) {
        this.client = client;
        client.defaults.timeout = 20000
        if (this.props.hasOwnProperty("onReady")) {
            this.props.onReady(this);
        }
    }

    clientErrored(error) {
        console.log("clientLoaded errored");
        console.log(error);
    }

    getIdentity(callback, errorCallback) {
        this.client.get(`identity`)
            .then(function (response) {
                callback(response.data);
            }).catch(function (error) {
                console.log(error);
                if (errorCallback) {
                    errorCallback(error);
                }
            });
    }

    getAuthorizedWorkflowTypes() {
        return this.client.get(`portal/getAuthorizedWorkflowTypes`);
    }

    getExecutionList(type, metadataId, currentPageIndex, filterinfo) {
        return this.client.post(`portal/getExecutionList`, {
            type: type,
            metadataId: metadataId,
            currentPage: currentPageIndex,
            filterinfo: filterinfo
        });
    }

    getWorkflowInputsByType(type) {
        return this.client.get(`portal/getWorkflowInputsByType?id=${type}`);
    }

    startNewExecution(executionType, executionName, inputJson) {
        return this.client.post(`portal/createWorkflowExecution`, {
            executionType: executionType,
            executionName: executionName,
            inputJson: inputJson
        })
    }

    getSellerInfoBySellerId(type, internalSellerId) {
        // internalSellerId=d86d613e-d4e3-4746-8dff-baec467169e7&workflowType=Product_Publish_Workflow
        return this.client.get(`portal/getSellerInfo?workflowType=${type}&internalSellerId=${internalSellerId}`);
    }

    getSellerIdBySellerName(type, sellerCompanyName) {
        // sellerCompanyName=宁夏西云数据科技有限公司&workflowType=Product_Publish_Workflow
        return this.client.get(`portal/searchSellerByName?workflowType=${type}&sellerCompanyName=${sellerCompanyName}`);
    }

    getWorkflowDetailsById(workflowId) {
        return this.client.get(`portal/getExecutionDetailById?executionId=${workflowId}`);
    }

    getExecutionHistoryById(workflowId, currentPageIndex) {
        return this.client.get(`portal/getExecutionHistoryById?executionId=${workflowId}&currentPage=${currentPageIndex}&pageSize=2`);
    }

    sendTaskStatus(executionId, workflowType, nodeId, status, inputJson) {
        return this.client.post(`portal/sendTaskStatus`, {
            executionId, workflowType, nodeId, status, inputJson
        });
    }

    saveExecution(executionId, workflowType, nodeId, inputJson) {
        return this.client.post(`portal/saveExecution`, {
            executionId, workflowType, nodeId, inputJson
        });
    }

    getUploadFileSingedUrl(fileName) {
        return this.client.get(`portal/getFileUploadInfo?fileName=${fileName}`)
    }

    getDownloadUrl(executionId, widgetId) {
        return this.client.get(`portal/getFileDownloadUrl?widgetId=${widgetId}&executionId=${executionId}`)
    }

    getPreviewUrl(executionId, widgetId) {
        return this.client.get(`portal/getPreviewFileDownloadUrl?widgetId=${widgetId}&executionId=${executionId}`)
    }

    exportFile(type) {
        return this.client.get(`portal/exportFile?workflowType=${type}`)
    }

    getProductInfoByProductCode(productCode) {
        return this.client.get(`portal/getProductInfo?productCode=${productCode}`);
    }

    cancelExecution(executionId) {
        return this.client.post(`portal/cancelExecution`, {
            executionId: executionId
        })
    }
}
