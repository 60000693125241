import React from 'react';
import {Button, Form, SpaceBetween} from "@amzn/awsui-components-react";
import {SingletonServiceClientBuilder} from "../APIs";
import {Widget} from "../Component/Widget";
import {SUBMIT_SUCCESS_TIP, SAVE_SUCCESS_TIP} from '../common/strings';
import {ArrayWidget} from "../Component/Widget/ArrayWidget";

const initialDisplayMsg = "选择流程图中的任意节点以查看其详细信息。";
const noDetailsToDisplayMsg = "此节点没有可显示的详细信息。";
const noOpsToDisplayMsg = "您无权操作此节点，请联系相关同事进行处理";

export class NodeDataForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            alertVisible: false,
            alertMsg: "",
            isSubmitNodes: {},
            isSaveNodes: {},
            widgetListByNode: {},
            operatorByNode: {}
        };
        this.client = SingletonServiceClientBuilder.getClient();

        this.getWidgetValue = this.getWidgetValue.bind(this);
        this.setWidgetValue = this.setWidgetValue.bind(this);
        this.props.nodeList.forEach(node => {
            this.state.widgetListByNode[node.id] = Widget.loadWidgetsFromNode(node);
            this.state.operatorByNode[node.id] = {operator: node.operator}
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.nodeList !== this.props.nodeList) {
            this.reloadNodeList(this.props.nodeList);
            this.setSubmitStatus(this.props.nodeList);
            this.setSaveStatus(this.props.nodeList);
        }
    }

    reloadNodeList(nodeList) {
        let widgetListByNode = {};
        let operatorByNode = {};
        nodeList.forEach(node => {
            widgetListByNode[node.id] = Widget.loadWidgetsFromNode(node);
            operatorByNode[node.id] = {operator: node.operator}
        });
        this.setState({widgetListByNode, operatorByNode});
    }

    getWidgetValue(widgetId) {
        const widget = this.state.widgetListByNode[this.props.selectedNodeId].filter(widget => widget.id === widgetId);
        return widget.value;
    }

    setWidgetValue(widgetId, value) {
        let widgetListByNode = this.state.widgetListByNode;
        let widgetList = widgetListByNode[this.props.selectedNodeId];
        let widget = widgetList.filter(widget => widget.id === widgetId)[0];
        widget.value = value;
        widget.errorText = null;
        widgetListByNode[this.props.selectedNodeId] = widgetList;
        this.setState({
            widgetListByNode: widgetListByNode,
        });
    }

    restructureWidget(node, widgetList) {
        // Re-structure widget format according to service requirement.
        let mergedWidgetList = widgetList.map(widget => {
            if (widget.type === 'array') {
                const prevValues = JSON.parse(node.renderWidgetList.find(w => w.id === widget.id).value);
                if (prevValues == null) {
                    return {id: widget.id, type: widget.type, value: JSON.stringify(widget.value)};
                }
                return {
                    id: widget.id,
                    type: widget.type,
                    value: JSON.stringify(ArrayWidget.mergeWithPreviousWidgetValue(prevValues, widget.value)),
                };
            }
            return {id: widget.id, type: widget.type, value: widget.value};
        });
        return mergedWidgetList;
    }

    validateWidget(widgetList, decisionWidget, buttonType) {
        const shouldSkipValidation = decisionWidget && decisionWidget.value === 'reject';

        // In rollback case, user is not required to input any information. Skip validation in FAILURE case.
        if (!shouldSkipValidation) {

            // Validate current widgets.
            const validatedWidgets = Widget.validateWidgetValues(widgetList, buttonType);

            // Update widgets to reflect all failed validations.
            let widgetListByNode = this.state.widgetListByNode;
            widgetListByNode[this.props.selectedNodeId] = validatedWidgets;
            this.setState({
                widgetListByNode: widgetListByNode,
            });

            // If any widget still contains error, don't submit.
            const numWidgetErrors = validatedWidgets
                .map(widget => Widget.containsError(widget))
                .reduce((prev, cur) => prev || cur);
            if (numWidgetErrors > 0) {
                console.error("Some fields failed to pass validation");
                return;
            }

            widgetList = validatedWidgets;
        }
        return widgetList;
    }

    handleSave() {
        console.log("click save")
        const node = this.props.nodeList.find(node => node.id === this.props.selectedNodeId);
        let widgetList = this.state.widgetListByNode[this.props.selectedNodeId];
        const decisionWidget = widgetList.find(widget => widget.id.includes('Decision'));
        let buttonType = "Save"

        // Validate the widget
        widgetList = this.validateWidget(widgetList, decisionWidget, buttonType);

        // Re-structure widget format according to service requirement.
        let mergedWidgetList = this.restructureWidget(node, widgetList);

        const executionId = this.props.workflowId;
        const workflowType = this.props.workflowType;
        const nodeId = this.props.selectedNodeId;
        const inputJson = JSON.stringify(mergedWidgetList);

        this.setState({
            ...this.state,
            isSaveNodes: {
                ...this.state.isSaveNodes,
                [nodeId]: true
            }
        });
        this.client.then((result) => {
            result.saveExecution(executionId, workflowType, nodeId, inputJson)
                .then(result => {
                    console.log("SaveExecution OK:", result);
                    this.props.setSuccessMsg(nodeId, SAVE_SUCCESS_TIP);
                    PubSub.publish('updateWorkflow');
                })
                .catch(err => {
                    console.log("SaveExecution ERR:", err);
                    this.props.setAlertMsg(err);
                });
        });
    }

    handleSubmit() {
        const node = this.props.nodeList.find(node => node.id === this.props.selectedNodeId);
        let widgetList = this.state.widgetListByNode[this.props.selectedNodeId];
        const decisionWidget = widgetList.find(widget => widget.id.includes('Decision'));
        let buttonType = "Submit"

        // Validate the widget
        widgetList = this.validateWidget(widgetList, decisionWidget, buttonType);
        // Re-structure widget format according to service requirement.
        let mergedWidgetList = this.restructureWidget(node, widgetList);

        const status = !decisionWidget || decisionWidget.value === 'approve' ? 'SUCCESS' : 'FAILURE';
        const executionId = this.props.workflowId;
        const workflowType = this.props.workflowType;
        const nodeId = this.props.selectedNodeId;
        const inputJson = JSON.stringify(mergedWidgetList);

        this.setState({
            ...this.state,
            isSubmitNodes: {
                ...this.state.isSubmitNodes,
                [nodeId]: true
            }
        });
        this.client.then((result) => {
            result.sendTaskStatus(executionId, workflowType, nodeId, status, inputJson)
                .then(result => {
                    console.log("SendTaskStatus OK:", result);
                    this.props.setSuccessMsg(nodeId, SUBMIT_SUCCESS_TIP);
                    PubSub.publish('updateWorkflow');
                })
                .catch(err => {
                    console.log("SendTaskStatus ERR:", err);
                    this.props.setAlertMsg(err);
                });
        });
    }

    setSubmitStatus = (nodeList) => {
        let isSubmitNodeList = {};
        nodeList.forEach(node => {
            let status = !(node.status === "Active");
            isSubmitNodeList[node.id] = status;
        });
        this.setState({
            isSubmitNodes: isSubmitNodeList,
        });
    }

    setSaveStatus = (nodeList) => {
        let isSaveNodeList = {};
        nodeList.forEach(node => {
            let status = !(node.status === "Active");
            isSaveNodeList[node.id] = status;
        });
        this.setState({
            isSaveNodes: isSaveNodeList,
        });
    }

    render() {
        // Only display details if the node is returned by API call
        if (!this.props.selectedNodeId) {
            return initialDisplayMsg;
        }
        const widgetList = this.state.widgetListByNode[this.props.selectedNodeId];
        const operatorList = this.state.operatorByNode[this.props.selectedNodeId];
        if (!widgetList) {
            return noDetailsToDisplayMsg;
        }
        if (widgetList.length === 0) {
            return noOpsToDisplayMsg;
        }
        const renderedWidgets = widgetList.map(widget => Widget.getWidgetByType(widget, this.setWidgetValue));
        const renderedOperator = Widget.getOperatorField(operatorList)
        const editable = this.props.nodeList.filter(node => node.id === this.props.selectedNodeId)[0]?.editable;
        const submitButton = editable ?
            (<Button loading={this.state.isSubmitNodes[this.props.selectedNodeId]} variant="primary">Submit</Button>)
            : null;
        const saveButton = editable ?
            (<Button
                loading={this.state.isSaveNodes[this.props.selectedNodeId]}
                variant="normal"
                formAction="none"
                label="save"
                onClick={e => {
                    e.preventDefault();
                    this.handleSave();
                }}
                >Save</Button>)
            : null;

        return (
            <form onSubmit={e => {
                e.preventDefault();
                this.handleSubmit();
            }}>
                <Form
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            {saveButton}
                            {submitButton}
                        </SpaceBetween>
                    }
                >
                    <SpaceBetween direction="vertical" size="m">
                        {renderedOperator}
                        {renderedWidgets}
                    </SpaceBetween>
                </Form>
            </form>
        );
    }

}