import Link from "@amzn/awsui-components-react/polaris/link";

export const NOT_FOUND_404 = '404';
export const PAGE_DOES_NOT_EXIST = 'Sorry, the page you were looking for does not exist. Please visit the homepage for more information.';
export const HOMEPAGE = ' https://prod.bpm.cn-northwest-1.marketplace.aws.a2z.org.cn/'

export const EXPORT_FAIL_HEADER = 'Failed to export launched products'
export const EXPORT_FAIL_TIP = 'Some internal error has occurred. Please try again later or contact the development team.'
export const EXPORT_SUCCESS_HEADER = 'Launched products have been exported successfully.'
export const EXPORT_SUCCESS_TIP = 'Please checkout the downloaded excel form.'

export const INTERNAL_ERROR = 'Internal Server Error'
export const INTERNAL_ERROR_TIP = 'Some internal error has occurred. Please try again later or contact the development team.'
export const NOT_FOUND = 'Not Found'
export const NOT_FOUND_TIP = 'Resources are not found. Please check your input and try again.'
export const UNAUTHORIZED = 'Unauthorized'
export const UNAUTHORIZED_TIP =
    <div>Sorry, you are not authorized to access this resource. Please join the correct posix group or contact the development team.
        <br></br>
        <Link href={'https://issues.cn-northwest-1.amazonaws.cn/issues/create?template=2d6665ab-2fe6-4405-902b-e7c46a9fcac8'} variant="primary" external>
            Report a problem.
        </Link>
    </div>

export const UNAUTHORIZED_CREATE_TIP =
    <div>Sorry, you are not authorized to create this resource. Please join the correct posix group or contact the development team.
        <br></br>
        <Link href={'https://issues.cn-northwest-1.amazonaws.cn/issues/create?template=2d6665ab-2fe6-4405-902b-e7c46a9fcac8'} variant="primary" external>
            Report a problem.
        </Link>
    </div>

export const SELLER_NOT_FOUND = 'No Seller Information Found'
export const SELLER_NOT_FOUND_TIP = 'The Internal Seller Id does not found in existing sellers. Please check your input or download previous workflows to get correct internal seller id.'
export const PRODUCT_NOT_FOUND = 'No Product Information Found'
export const PRODUCT_NOT_FOUND_TIP = 'The BPM product id does not found in existing products. Please check your input or download previous workflows to get correct BPM product id.'

export const ID_NOT_EXIST = 'Workflow ID not found.'
export const ID_NOT_EXIST_TIP = 'The workflow ID does not exist. Please check your input and try again.'

export const SUBMIT_SUCCESS_TIP = 'Information was submitted successfully.'
export const SAVE_SUCCESS_TIP = 'Information was saved successfully.'
export const DOWNLOAD_FILE_SUCCESS = 'File was downloaded successfully. Please checkout your download folder.'
export const CANCELLED_SUCCESS_TIP = 'Workflow canceled successfully.'