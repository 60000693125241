import React from 'react';
import { NOT_FOUND_404, PAGE_DOES_NOT_EXIST, HOMEPAGE } from "./common/strings";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import Link from "@amzn/awsui-components-react/polaris/link";


export const NotFound = () => {
    return (
        <Alert
            onDismiss={false}
            visible={true}
            dismissAriaLabel="Close alert"
            type="error"
            header={NOT_FOUND_404}
        >
            <div>
                {PAGE_DOES_NOT_EXIST}
                <br></br>
            <Link href={HOMEPAGE} variant="primary">
                {HOMEPAGE}
            </Link>
            </div>
        </Alert>
    )
};


