import Box from "@amzn/awsui-components-react/polaris/box";
import {ColumnLayout} from "@amzn/awsui-components-react";
import React from "react";

export const renderWhiteSpace = (value) => {
    if (!value) {
        return "/";
    }
    let br = <br></br>;
    let result = null;
    let audits = value.split('\n');
    for (let j = 0; j < audits.length -1; j++) {
        if (j == 0) {
            result = <h6>{audits[j]}</h6>;
        } else {
            let res = audits[j].split(':')
            if((res[0]==""|| res[0]===undefined )&& (res[1]==""|| res[1]===undefined )){
                continue;
            }
            result = <span>{result} <b>{res[0]}</b> {": "+ res[1]}{br}</span>;
        }
    }
    return <div>{result}</div>;
}

export const renderNewLine = (value) => {
    // value would be group with pending days, seperate with ","
    // eg. aws-mp-bpm-test02:5 hours, aws-mp-bpm-test03: 1 day 3 hours
    if (!value) {
        return "-";
    }
    let br = <br></br>;
    let result = null;
    let audits = value.split(', ');
    for (let j = 0; j < audits.length; j++) {
        let res = audits[j].split(':')
        if((res[0]==""|| res[0]===undefined )&& (res[1]==""|| res[1]===undefined )){
            continue;
        }
        result = <span>{result} <b>{res[0]}</b> {": "+ res[1]}{br}</span>;
    }
    return <div>{result}</div>;
}

export const getDisplayedEvent = (value) => {
    if (!value) {
        return "-";
    } else if(value === "HUMAN_CREATE_WORKFLOW") {
        return "Start New Workflow"
    } else if(value === "HUMAN_UPDATE_WORKFLOW") {
        return "Approve Process"
    } else if(value === "HUMAN_ROLLBACK_WORKFLOW") {
        return "Reject Process"
    } else if(value === "HUMAN_CANCEL_WORKFLOW") {
        return "Cancel workflow"
    } else if(value === "HUMAN_SAVE_WORKFLOW") {
        return "Save Process"
    } else return value
        
}
export const AuditHistoryCell = item =>
    <ColumnLayout columns={1}>
        <div>
            <Box variant="awsui-key-label">{(item.gmtCreate || "-") + ", " + getDisplayedEvent(item.opDetail) + ", " + (item.opUser || "-")}</Box>
        </div>
        <div>
            <Box variant="awsui-key-label">Old Value</Box>
            <div>{renderWhiteSpace(item.oldValue)}</div>
        </div>
        <div>
            <Box variant="awsui-key-label">New Value</Box>
            <div>{renderWhiteSpace(item.newValue)}</div>
        </div>
    </ColumnLayout>


export const HISTORY_COLUMN_DEFINITIONS =
    [
        {
            cell: AuditHistoryCell,
        }
    ]


export const AuditHistoryEmpty =
    <Box textAlign="center" color="inherit">
        <b>No resources</b>
        <Box
            padding={{ bottom: "s" }}
            variant="p"
            color="inherit"
        >
            No resources to display.
        </Box>
    </Box>

