import React from 'react';
import {BaseWorkflow} from "./BaseWorkflow";

export class AMMPSellerProfileReviewWorkflow extends BaseWorkflow {
    paintVertexBasedOnStatus(vertex, nodeMap) {
        const node = nodeMap.get(vertex.id);
        console.log(node);
        if (node === undefined) {
            // Special treatment for PendingCustomerPaymentinitializeReviewAuto
            return;
        }

        // Regular color scheme
        this.defineRegularStatusColor(node, vertex)
    }

    generateGraphElements(nodeList) {
        const nodeMap = new Map();
        nodeList.forEach(node => {
            nodeMap.set(node.id, node);
        });
        const vertices = [
            {id: 'SellerProfileInformation', data: {label: '卖家Profile信息'}, position: this.generateCenterPosition({x: 0, y: 0})},
            {id: 'PendingNwcdCmReviewSellerProfile', data: {label: 'NWCD团队审核'}, position: this.generateCenterPosition({x: 0, y: 150})},
            {id: 'PendingMcoReviewSellerProfile', data: {label: 'MCO团队审核'}, position: this.generateCenterPosition({x: 0, y: 300})},
            {id: 'PublishSellerProfileComplete', data: {label: '发布Profile并通知卖家'}, position: this.generateCenterPosition({x: 0, y: 450})},
        ];
        vertices.forEach(vertex => {
            this.paintVertexBasedOnStatus(vertex, nodeMap);
        });
        const edges = [
            {id: 'e-SellerProfileInformation-PendingNwcdCmReviewSellerProfile', source: 'SellerProfileInformation', target: 'PendingNwcdCmReviewSellerProfile'},
            {id: 'e-PendingNwcdCmReviewSellerProfile-PendingMcoReviewSellerProfile', source: 'PendingNwcdCmReviewSellerProfile', target: 'PendingMcoReviewSellerProfile'},
            {id: 'e-PendingMcoReviewSellerProfile-PublishSellerProfileComplete', source: 'PendingMcoReviewSellerProfile', target: 'PublishSellerProfileComplete'},
        ];
        return [...vertices, ...edges];
    }
}