import {DatePicker, FormField} from "@amzn/awsui-components-react";
import React from "react";

export class DateWidget extends React.Component {
    render() {
        return (
            <FormField label={this.props.widget.name} errorText={this.props.widget.errorText}>
                <DatePicker nextMonthAriaLabel="Next month"
                            placeholder="YYYY/MM/DD"
                            previousMonthAriaLabel="Previous month"
                            onChange={({detail}) => {
                                this.props.setValue(this.props.widget.id, detail.value)
                            }}
                            value={this.props.widget.value || ''}
                            disabled={this.props.widget.locked || this.props.widget.readonly}
                            todayAriaLabel="Today" />
            </FormField>
        )

    }
}
