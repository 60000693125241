import {FormField, Input} from "@amzn/awsui-components-react";
import React from "react";

export class TextWidget extends React.Component {
    render() {
        return (
            <FormField label={this.props.widget.name} errorText={this.props.widget.errorText}>
                <Input
                    onChange={({detail}) => {
                        this.props.setValue(this.props.widget.id, detail.value);
                    }}
                    value={this.props.widget.value}
                    disabled={this.props.widget.locked || false}
                    readOnly={this.props.widget.readonly}
                />
            </FormField>
        );
    }
}