import * as React from "react";
import {FileUpload} from "./FileUpload/FileUpload"
import {uploadObjectUsingPresignedURL, SingletonServiceClientBuilder} from "../../APIs";
import {Button, FormField, Header, SpaceBetween} from "@amzn/awsui-components-react";
import Link from "@amzn/awsui-components-react/polaris/link";

export class FileUploadWidget extends React.Component {
    constructor(props) {
        super(props);
        const mockedFiles = this.createMockFiles(Array.isArray(props.value) ? props.value : [props.value]);
        this.state = {
            loading: false,
            numOfFiles: 0,
            files: mockedFiles,
            fileName: props.value,
            errorMsg: {visible: false}
        };
        this.client = SingletonServiceClientBuilder.getClient();
    }

    createMockFiles(fileURLs) {
        return fileURLs.reduce(function (result, filename) {
            if (filename !== "") {
                const mockFile = new Blob([""], {type: "uploaded to s3"});
                mockFile.name = filename;
                result.push(mockFile);
            }
            return result;
        }, []);
    }

    onFileSelect(selected) {
        console.log("fileSelected")
    }

    onFileDeselect(e) {
        this.setState({
            numOfFiles: 0,
            loading: false,
            files: []
        });
        this.props.setValue(this.props.widget.id, "");
    }

    async onUpload(files = []) {
        this.setState({
            loading: files.length > 0,
            numOfFiles: files.length,
            errorMsg: {
                visible: false
            },
            files: [],
            fileName: ""
        });
        this.props.setValue(this.props.widget.id, "");

        if (!this.checkFileExtensions(files, ["jpg", "jpeg", "pdf", "png", "doc", "docx", "xlsx", "xls"])) {
            this.setState({
                loading: false,
                errorMsg: {
                    visible: true,
                    header: "file type error",
                    tip: "File type does not match, only files in jpg, jpeg, png, pdf,doc,docx,xls,xlsx format are supported."
                }
            })
            return
        }

        if (!this.checkFileSize(files[0], 100 * 1024 * 1024)) {
            this.setState({
                loading: false,
                errorMsg: {
                    visible: true,
                    header: "file size error",
                    tip: "File size needs to be less than 100M."
                }
            })
            return
        }
        const file = files[0];

        try {
            const result = await this.client;
            const response = await result.getUploadFileSingedUrl(file.name);

            await uploadObjectUsingPresignedURL(file, response.data.signedUrl);

            this.props.setValue(this.props.widget.id, response.data.objectName);

            this.setState({
                numOfFiles: 0,
                loading: false,
                files: [file],
                fileName: response.data.objectName,
                errorMsg: {
                    visible: false
                }
            });
        } catch (e) {
            this.setState({
                loading: false,
                errorMsg: {
                    visible: true,
                    header: "System error",
                    tip: "Please check the file name and do not contain special characters except underscores. If it still fails after trying again, please contact SED."
                }
            });
        }
    }


    checkFileSize(file, fileSize) {
        if (fileSize <= 0) {
            return true;
        }
        return file.size < fileSize
    }

    // Returns true if all extensions match, else false. If no file types are given, accept all files
    checkFileExtensions(files, fileTypes) {
        if (fileTypes.length === 0) {
            return true;
        }

        const fileTypesFormatted = fileTypes.map(fileType => fileType.toLowerCase());

        return files.reduce((acc, file) => {
            acc =
                acc &&
                fileTypesFormatted.includes(
                    file.name
                        .split(".")
                        .pop()
                        .toLowerCase()
                );
            return acc;
        }, true);
    }

    async preview(widget) {
        try {
            const executionId = window.location.href.split('/').pop().replace(/^#+|#+$/g, '');

            this.client.then((result) => {
                result.getPreviewUrl(executionId, widget.id).then((response) => {
                    window.open(response.data);
                }, e => {
                    console.log(e);
                });
            })
        } catch (e) {
            console.log(e);
        }
    }

    isSupportPreview(widget) {
        if (this.props.widget.supportPreview != true) {
            return false;
        }

        try {
            const executionId = window.location.href.split('/').pop().replace(/^#+|#+$/g, '');
            return executionId
        } catch (e) {
            return false;
        }
    }

    render() {
        //now, only detail page can preview file,which mean it must have executionId parameter
        if (this.isSupportPreview(this.props.widget)) {
            return (<FormField
                label={this.props.widget.name}
                required={this.props.required}
                description={
                    <>
                        点击可进行{" "}
                        <Link
                            // href={hrefUrl}
                            onFollow={e => this.preview(this.props.widget)}
                            external="true"
                            variant="primary"
                            fontSize="body-s"
                        >
                            预览
                        </Link>{" "}
                    </>
                }
            >
                <FileUpload
                    disabled={this.props.widget.locked || this.props.widget.readonly || false}
                    fileName={this.state.fileName}
                    files={this.state.files}
                    loading={this.state.loading}
                    multiple={false}
                    errorMsg={this.state.errorMsg}
                    onFileDeselect={e => this.onFileDeselect(e)}
                    onFileSelect={selected => this.onFileSelect(selected)}
                    onUpload={files => this.onUpload(files)}
                    widget={this.props.widget}
                />
            </FormField>)
        }

        return <FormField
            label={this.props.widget.name}
            required={this.props.required}
        >
            <FileUpload
                disabled={this.props.widget.locked || this.props.widget.readonly || false}
                fileName={this.state.fileName}
                files={this.state.files}
                loading={this.state.loading}
                multiple={false}
                errorMsg={this.state.errorMsg}
                onFileDeselect={e => this.onFileDeselect(e)}
                onFileSelect={selected => this.onFileSelect(selected)}
                onUpload={files => this.onUpload(files)}
                widget={this.props.widget}
            />
        </FormField>
    }
}