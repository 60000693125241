import {FormField, Select} from "@amzn/awsui-components-react";
import React from "react";

const ChoiceValueLabelOverride = {
    approve: '通过',
    reject: '驳回',
    true: '是',
    false: '否',
    yes: '是',
    no: '否',
};

const UFWChoiceValueLabelOverride = {
    approve: '已付款',
    reject:  '未付款',
}

 
export class ChoiceWidget extends React.Component {
     makeOption(value, id) {
        let label
        if (id == "opsDecision") {
            label = UFWChoiceValueLabelOverride[value];
        } else {
            label = ChoiceValueLabelOverride[value];
        }
        return {
            label: label || value,
            value: value,
        };
    }

    render() {
        const format = this.props.widget.format || this.props.widget.rule;
        let optionList;
        if (format) {
            optionList = JSON.parse(format).map(elem => 
                this.makeOption(elem, this.props.widget.id)
            );
        } else {
            optionList = [this.makeOption(this.props.widget.value, this.props.widget.id)];
        }
        return (
            <FormField label={this.props.widget.name} errorText={this.props.widget.errorText}>
                <Select
                    selectedOption={this.makeOption(this.props.widget.value, this.props.widget.id)}
                    onChange={({detail}) => {
                        this.props.setValue(this.props.widget.id, detail.selectedOption.value);
                    }}
                    options={optionList}
                    disabled={this.props.widget.locked || this.props.widget.readonly}
                />
            </FormField>
        );
    }
}
