import React from 'react';
import {BaseWorkflow} from "./BaseWorkflow";

export class ProductUpdate2PFlow extends BaseWorkflow {
    paintVertexBasedOnStatus(vertex, nodeMap) {
        const node = nodeMap.get(vertex.id);

        // Special handling of TaxReview
        if (node === undefined) {
            if (vertex.id === 'TaxReview' && nodeMap.get("LegalReview") !== undefined) {
                vertex.style = {background: this.StatusColor.Completed};
                vertex.data.label = vertex.data.label + '（已跳过）';
            }
            return;
        }

        // Regular color scheme
        this.defineRegularStatusColor(node, vertex)
    }

    generateGraphElements(nodeList) {
        const nodeMap = new Map();
        nodeList.forEach(node => {
            nodeMap.set(node.id, node);
        });
        const vertices = [
            {id: 'BDInfoUpload', data: {label: 'BD信息上传'}, position: this.generateCenterPosition({x: 0, y: 0})},
            {id: 'TaxReview', data: {label: '税务审核'}, position: this.generateCenterPosition({x: 0, y: 150})},
            {id: 'LegalReview', data: {label: '法务审核'}, position: this.generateCenterPosition({x: 0, y: 300})},
            {id: 'CETProcessing', data: {label: 'CET处理'}, position: this.generateCenterPosition({x: 0, y: 450})},
        ];
        vertices.forEach(vertex => {
            this.paintVertexBasedOnStatus(vertex, nodeMap);
        });
        const edges = [
            {id: 'e-BDInfoUpload-TaxReview', source: 'BDInfoUpload', target: 'TaxReview'},
            {id: 'e-TaxReview-LegalReview', source: 'TaxReview', target: 'LegalReview'},
            {id: 'e-BDInfoUpload-LegalReview', source: 'BDInfoUpload', target: 'LegalReview'},
            {id: 'e-LegalReview-CETProcessing', source: 'LegalReview', target: 'CETProcessing'},
        ];
        return [...vertices, ...edges];
    }
}