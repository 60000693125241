import React from 'react';
import {
    Box,
    ColumnLayout,
    Container,
    Header,
    Button,
    SpaceBetween,
    Modal
} from '@amzn/awsui-components-react';

import { renderStatusField } from '../CommonWidgetRenders';
import { SingletonServiceClientBuilder } from '../APIs'
import {CANCELLED_SUCCESS_TIP} from '../common/strings'

export default class BasicDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteVisible: false
        }
        this.client = SingletonServiceClientBuilder.getClient();
    }
    cancelExecution = (executionId) => {
        this.client.then((result) => {
            result.cancelExecution(executionId).then((response) => {
                this.props.setSuccessMsg(executionId, CANCELLED_SUCCESS_TIP)
                PubSub.publish('updateWorkflow')
            }, e => {
                this.props.setAlertMsg(e)
            })
        })
        this.setState({
            deleteVisible: false
        })
   }
    renderCancelButton = (details) => {
        if (details.canCancel === true) {
            return <SpaceBetween direction="horizontal" size="xs">
                <Button variant="primary" onClick={()=>this.setState({deleteVisible:true})}>Cancel</Button>
            </SpaceBetween>
        }
    }
    renderAssignedGroup = (details) => {
        return (
            details == "" ? "" : details.assignedGroups.join(", ")
        )
    }
    render() {
        const details = this.props.details
        return (
            <SpaceBetween size="l">
                <Container
                    header={<Header variant="h2" actions={this.renderCancelButton(details)}>Workflow Information</Header>}>
                    <ColumnLayout columns={3} variant="text-grid">
                        <div>
                            <Box variant="awsui-key-label">Status</Box>
                            <div>{renderStatusField(details.status)}</div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">Workflow type</Box>
                            <div>{details.workflowType}</div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">Workflow Id</Box>
                            <div>{details.id}</div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">Requested By</Box>
                            <div>{details.requestedBy}</div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">Assigned Group(s)</Box>
                            <div>{this.renderAssignedGroup(details)}</div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">Created On (UTC+08:00)</Box>
                            <div>{new Date(details.gmtCreate).toLocaleString()}</div>
                        </div>
                    </ColumnLayout>
                </Container>

                <Modal
                    onDismiss={() => this.setState({ deleteVisible: false })}
                    visible={this.state.deleteVisible}
                    closeAriaLabel="Close modal"
                    header={'Cancel workflow?'}
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="link" onClick={() => this.setState({ deleteVisible: false })}>No</Button>
                                <Button variant="primary" onClick={() => this.cancelExecution(details.id)}>Yes, cancel workflow</Button>
                            </SpaceBetween>
                        </Box>
                    }

                >
                    <Box variant="span">
                        Cancel workflow{' '}
                        <b>{details.id}</b>
                        {' '}permanently? This action cannot be undone.
                    </Box>
                </Modal>
            </SpaceBetween>
        );
    }
}