import * as React from "react";
import Wizard from "@amzn/awsui-components-react/polaris/wizard";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import {Container, Header, SpaceBetween} from "@amzn/awsui-components-react";
import {SingletonServiceClientBuilder} from "../APIs";
import {StartWorkflowDataForm} from "./StartWorkflowDataForm";
import {Widget} from "../Component/Widget";
import {
    INTERNAL_ERROR,
    INTERNAL_ERROR_TIP,
    NOT_FOUND,
    NOT_FOUND_TIP,
    UNAUTHORIZED,
    UNAUTHORIZED_CREATE_TIP,
    PRODUCT_NOT_FOUND,
    PRODUCT_NOT_FOUND_TIP,
    SELLER_NOT_FOUND,
    SELLER_NOT_FOUND_TIP,
} from '../common/strings'
import PubSub from 'pubsub-js'
import store from "../store"

const WorkflowMetadataMap = {
    '2pProductLaunchExistingSeller': 'Product_Publish_Workflow',
    '2pProductLaunchNewSeller': 'Product_Publish_Workflow',
};

export class StartWorkflowPageView extends React.Component {
    constructor(props) {
        super(props);

        this.metadataId = WorkflowMetadataMap[this.props.workflowType] || this.props.workflowType;

        this.state = {
            widgetList: [],
            inputs: [],
            recordedInputs: [],
            isLoading: true,
            isLoadingToSubmit: false,
            activeStepIndex: 0,
            showInputs: !(this.props.workflowType === "2pProductLaunchExistingSeller" || this.props.workflowType === "Product_Update_Workflow"),
            alertMessage: {
                visible: false,
                type: 'error',
                header: '',
                tip: ''
            },
            workflowTypesError: store.getState().workflows.workflowTypesError
        }
        this.setWidgetValue = this.setWidgetValue.bind(this);
        this.client = SingletonServiceClientBuilder.getClient();
        store.subscribe(() => {
            this.setState({
                workflowTypesError: store.getState().workflows.workflowTypesError
            });
        })
    }

    loadDataforGetWorkflowInputsByType = () => {
        console.log("GetWorkflowInputsByType");
        this.client.then(client => client.getWorkflowInputsByType(this.metadataId).then((response) => {
            if (this.state.workflowTypesError != undefined) {
                this.setAlertMsg(this.state.workflowTypesError, "updateWorkflowError")
            }
            // note: It's possible that this call returns later than getProductInfo/getSellerInfo, which may have already
            //      fetched some data and saved in widget.value. This call should be careful not to overwrite any existing
            //      value of a widget, and not to overwrite the locked status of a widget.
            const widgetList = this.state.widgetList;
            let newWidgetList = [];
            response.data.inputs.forEach(widget => {
                newWidgetList.push({
                    id: widget["id"],
                    name: widget["name"],
                    type: widget["type"],
                    rule: widget["rule"],
                    renderList: widget["renderList"],
                    required: widget["required"],
                    locked: widgetList.find(w => w.id === widget.id)?.locked || false,
                    value: widget["defaultValue"] || '',
                    errorText: null,
                });
            });
            this.setState({
                widgetList: newWidgetList,
                isLoading: false,
            });
        }, e => {
            console.log(e);
            this.setAlertMsg(e, "getWorkflowInputsByType")
            this.setState({
                isLoading: false
            })
        }));
    }

    componentDidMount() {
        this.loadDataforGetWorkflowInputsByType()
        PubSub.subscribe('updateWorkflowError', (_, e) => {
            console.log(e)
            this.state.alertMessage.visible ? "" :
                this.setAlertMsg(e, "updateWorkflowError")
        })
    }

    getWidgetList() {
        return this.state.widgetList.filter(widget => widget.type);
    }

    setAlertMsg = (e, APIType) => {
        let header, tip
        if (e.response) {
            if (e.response.status == 404) {
                if (APIType == "getSellerInfoByInternalSellerId") {
                    header = SELLER_NOT_FOUND
                    tip = SELLER_NOT_FOUND_TIP
                } else if (APIType == "getProductInfoByBPMProductId") {
                    header = PRODUCT_NOT_FOUND
                    tip = PRODUCT_NOT_FOUND_TIP
                } else {
                    header = NOT_FOUND
                    tip = NOT_FOUND_TIP
                }
                this.setState({
                    alertMessage: {
                        visible: true,
                        type: 'error',
                        header: header,
                        tip: tip
                    }
                })
            } else if (e.response.status == 403) {
                this.setState({
                    alertMessage: {
                        visible: true,
                        type: 'error',
                        header: UNAUTHORIZED,
                        tip: UNAUTHORIZED_CREATE_TIP
                    }
                })
            } else {
                let msg = (e.response.data == null || e.response.data.message === null) ? INTERNAL_ERROR_TIP : e.response.data.message
                // handle error including status > 500 and 400
                this.setState({
                    alertMessage: {
                        visible: true,
                        type: 'error',
                        header: INTERNAL_ERROR,
                        tip: msg
                    }
                })
            }
        } else {
            this.setState({
                alertMessage: {
                    visible: true,
                    type: 'error',
                    header: e.message,
                    tip: e.stack
                }
            })
        }
    }

    setWidgetValue(widgetId, value, locked = false) {
        const newWidgetList = this.state.widgetList;
        const widget = newWidgetList.find(widget => widget.id === widgetId);
        if (!widget) {
            newWidgetList.push({id: widgetId, value: value, locked: locked});
        } else {
            widget.value = value;
            widget.locked = locked;
            widget.errorText = null;
        }
        this.setState({
            widgetList: newWidgetList,
        });

    }

    resetWidgets = () => {
        // clear widget values and set all widgets as unlocked
        this.setState({
            widgetList: this.state.widgetList.map(widget => {
                return {...widget, value: null, locked: false, errorText: null};
            }),
        });
    }

    handleSubmit() {
        // Validate current widgets.
        const validatedWidgets = Widget.validateWidgetValues(this.getWidgetList());

        // Update widgets to reflect all failed validations.
        this.setState({
            widgetList: validatedWidgets,
        });

        // If any widget still contains error, don't submit.
        const numWidgetErrors = validatedWidgets
            .map(widget => Widget.containsError(widget))
            .reduce((prev, cur) => prev || cur);
        if (numWidgetErrors > 0) {
            console.error("Some fields failed to pass validation");
            return;
        }

        // Re-structure widget format according to service requirement.
        const widgetValues = validatedWidgets.map(widget => {
            if (widget.type === 'array') {
                return {id: widget.id, value: JSON.stringify(widget.value), type: "array"};
            }
            return {id: widget.id, value: widget.value, type: widget.type || "string"};
        });
        this.setState({
            isLoadingToSubmit: true
        });
        let inputJson = JSON.stringify(widgetValues);

        //TODO: Read actual workflow type, we need to align the naming between BD metadata and front end
        this.client.then(client => client.startNewExecution(this.metadataId, "User_Started_Workflow", inputJson)
            .then(result => {
                    window.location.href = "/detail/" + result.data.workflowId;
                }, e => {
                    this.setAlertMsg(e, "startWorkflowError");
                    this.setState({
                        isLoadingToSubmit: false,
                    });
                }
            ));
    }

    render() {
        return (
            <SpaceBetween direction="vertical" size="l">
                <Alert
                    visible={this.state.alertMessage.visible}
                    dismissAriaLabel="Close alert"
                    type={this.state.alertMessage.type}
                    header={this.state.alertMessage.header}
                >
                    {this.state.alertMessage.tip}
                </Alert>
                <Wizard
                    i18nStrings={{
                        stepNumberLabel: stepNumber =>
                            `Step ${stepNumber}`,
                        collapsedStepsLabel: (stepNumber, stepsCount) =>
                            `Step ${stepNumber} of ${stepsCount}`,
                        previousButton: "Previous",
                        nextButton: "Next",
                        submitButton: "Start workflow",
                        optional: "optional"
                    }}
                    onNavigate={({detail}) => {
                        this.setState({
                            activeStepIndex: detail.requestedStepIndex,
                            alertMessage: {visible: false}
                        })
                    }}
                    onSubmit={() => {
                        this.handleSubmit();
                    }}
                    activeStepIndex={this.state.activeStepIndex}
                    isLoadingNextStep={this.state.isLoadingToSubmit}
                    steps={[
                        {
                            title: "Enter required inputs",
                            content: (
                                <Container
                                    header={
                                        <Header variant="h2">
                                            Required inputs
                                        </Header>
                                    }
                                >
                                    <StartWorkflowDataForm workflowType={this.props.workflowType}
                                                           widgetList={this.getWidgetList()}
                                                           setWidgetValue={this.setWidgetValue}
                                                           showInputs={this.state.showInputs}
                                                           setShowInputs={show => this.setState({showInputs: show})}
                                                           setAlertMsg={this.setAlertMsg}
                                                           setShowAlert={showAlert => this.setState({alertMessage: {visible: showAlert}})}
                                                           resetWidgets={this.resetWidgets}
                                    />
                                </Container>
                            )
                        },
                    ]}
                />
            </SpaceBetween>
        );
    }
}

export default () => {
    const params = new URLSearchParams(window.location.search);
    let type = params.get("type");
    return (
        <StartWorkflowPageView workflowType={type}/>
    )
}