import React from 'react';
import {BaseWorkflow} from "./BaseWorkflow";

export class ProductLaunch2PFlow extends BaseWorkflow {

    paintVertexBasedOnStatus(vertex, nodeMap) {
        const node = nodeMap.get(vertex.id);
        if (node === undefined) {
            // Special treatment for the two rollback state.
            // These nodes will be hidden if there's no rollback, and will be hidden for the update workflow the whole time.
            if (vertex.id === 'BDLegalInfoUpload' || vertex.id === 'BDTaxInfoUpload') {
                vertex.isHidden = true;
            }
            return;
        }

        // Regular color scheme
        this.defineRegularStatusColor(node, vertex)
    }

    generateGraphElements(nodeList) {
        const nodeMap = new Map();
        nodeList.forEach(node => {
            nodeMap.set(node.id, node);
        });
        const vertices = [
            {id: 'BDInfoUpload', data: {label: '信息上传'}, position: this.generateCenterPosition( {x: 0, y: 0})},
            {id: 'BDLegalInfoUpload', data: {label: '法务信息更新'}, position: this.generateCenterPosition({x: -150, y: 100})},
            {id: 'BDTaxInfoUpload', data: {label: '税务信息更新'}, position: this.generateCenterPosition({x: 150, y: 100})},
            {id: 'LegalReview', data: {label: '法务审核'}, position: this.generateCenterPosition({x: -150, y: 200})},
            {id: 'TaxReview', data: {label: '税务审核'}, position: this.generateCenterPosition({x: 150, y: 200})},
            {id: 'CETProcessing', data: {label: 'CET处理'}, position: this.generateCenterPosition({x: 0, y: 350})},
            {id: 'MCOPublishing', data: {label: 'MCO发布'}, position: this.generateCenterPosition({x: 0, y: 450})},
        ];
        vertices.forEach(vertex => {
            this.paintVertexBasedOnStatus(vertex, nodeMap);
        });
        const edges = [
            {id: 'e-BDInfoUpload-LegalReview', source: 'BDInfoUpload', target: 'LegalReview', type: 'smoothstep'},
            {id: 'e-BDInfoUpload-TaxReview', source: 'BDInfoUpload', target: 'TaxReview', type: 'smoothstep'},
            {id: 'e-BDLegalInfoUpload-LegalReview', source: 'BDLegalInfoUpload', target: 'LegalReview', type: 'smoothstep'},
            {id: 'e-BDTaxInfoUpload-TaxReview', source: 'BDTaxInfoUpload', target: 'TaxReview', type: 'smoothstep'},
            {id: 'e-LegalReview-CETProcessing', source: 'LegalReview', target: 'CETProcessing', type: 'smoothstep'},
            {id: 'e-TaxReview-CETProcessing', source: 'TaxReview', target: 'CETProcessing', type: 'smoothstep'},
            {id: 'e-CETProcessing-MCOPublishing', source: 'CETProcessing', target: 'MCOPublishing', type: 'smoothstep'},
        ];
        return [...vertices, ...edges];
    }
}