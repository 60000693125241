import React from 'react';
import ReactFlow from "react-flow-renderer";

const nodeOffset = 75;

export class BaseWorkflow extends React.Component {
    StatusColor = {
        Completed: '#2BD62E',
        ActiveEditable: '#53C9ED',
        ActiveNotEditable: '#DDDDDD',
        Rollback: '#ffd27f',
    };
    flowStyles = {height: 800};
    reactFlowId = 'reactFlow';

    constructor(props) {
        super(props);

        this.state = {
            center_x: 0,
            center_y: 0
        }
    }

    generateCenterPosition(absolutePosition) {
        return {x: absolutePosition.x + this.state.center_x - nodeOffset, y: absolutePosition.y + this.state.center_y}
    }

    componentDidMount() {
        const reactFlowDOM = document.getElementById(this.reactFlowId);
        let resizeObserver = new ResizeObserver(() => {
            this.recenterReactFlowGraph(reactFlowDOM)
        });
        resizeObserver.observe(reactFlowDOM);
    }

    defineRegularStatusColor(node, vertex) {
        // Regular color scheme
        if (node.status === 'Completed') {
            vertex.style = {background: this.StatusColor.Completed};
        } else if (node.status === 'Active') {
            if (node.editable === true) {
                vertex.style = {background: this.StatusColor.ActiveEditable};
            } else {
                vertex.style = {background: this.StatusColor.ActiveNotEditable};
            }
        } else if (node.status === 'Rollback') {
            vertex.style = {background: this.StatusColor.Rollback};
        }
    }

    recenterReactFlowGraph(reactFlowDOM) {
        this.setState({
            center_x:
                reactFlowDOM.offsetWidth / 2
        });
    }

    render() {
        return (
            <ReactFlow
                id={this.reactFlowId}
                elements={this.generateGraphElements(this.props.details.nodeList)}
                style={this.flowStyles}
                onElementClick={(event, element) => {
                    this.props.setSelectedNodeId(element.id);
                }}
                nodesDraggable={false}
            />
        );
    }
}