/**
 * The root of the react application. This is where it all comes together -
 * follow the imports of this file to find everything that is included in the
 * application.
 */

import React, { Component } from "react"
import ReactDOM from "react-dom"
import { StartWorkflowAppLayout, DetailPageLayout, WorkflowsAssignedToMeLayout, ListWorkflowsAppLayout } from './BPMAppLayout';
import { NotFound } from './NotFound'
import { Route, Redirect, BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store'
import { ErrorBoundary } from './ErrorBoundary'
import { Switch } from 'react-router';
import { SingletonServiceClientBuilder } from './APIs'
import PubSub from 'pubsub-js'
import TopNavigation from '@amzn/awsui-components-react/polaris/top-navigation';

import './css/bpmPortal.css'

export const i18nStrings = {
	searchIconAriaLabel: 'Search',
	searchDismissIconAriaLabel: 'Close search',
	overflowMenuTriggerText: 'More',
	overflowMenuTitleText: 'All',
	overflowMenuBackIconAriaLabel: 'Back',
	overflowMenuDismissIconAriaLabel: 'Close menu'
  };
export default class BPMApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workflowTypes: store.getState().workflows.workflowTypes,
      workflowTypesError: store.getState().workflows.workflowTypesError
    }
    store.subscribe(() => {
      this.setState({
        workflowTypes: store.getState().workflows.workflowTypes,
        workflowTypesError: store.getState().workflows.workflowTypesError
      });
    });
    this.client = SingletonServiceClientBuilder.getClient();
  }

  setWorkflowTypes = (option) => {
    store.dispatch({
      type: "workflowTypes",
      payload: option
    })
  }

  setworkflowTypesError = (option) => {
    store.dispatch({
      type: "workflowTypesError",
      payload: option
    })
  }

  componentDidMount() {
    console.log("call GetAuthorizedWorkflowTypes")
    this.loadDataForGetAuthorizedWorkflowTypes()
  }

  loadDataForGetAuthorizedWorkflowTypes = () => {
    this.client.then((result) => {
      result.getAuthorizedWorkflowTypes().then((response) => {
        let data = response.data;
        this.setWorkflowTypes(data)
      }, e => {
        console.log(e)
        this.setworkflowTypesError(e)
        PubSub.publish('updateWorkflowError',e)
      })
    })
  }

  render() {
    return (
      <React.Fragment>
        <ErrorBoundary>
          <Router basename="/">
            <Switch>
              <Route exact path='/' render={() => { return (<Redirect to='/cases/assigned-to-me' />) }} />
              <Route path='/cases/assigned-to-me' component={WorkflowsAssignedToMeLayout} />
              <Route path='/cases/list' component={ListWorkflowsAppLayout} />
              <Route path='/start-workflow' component={StartWorkflowAppLayout} />
              <Route path='/detail/:workflowId' component={DetailPageLayout} />
              <Route component={NotFound} />
            </Switch>
          </Router>
        </ErrorBoundary>
      </React.Fragment>
    )
  }
}

ReactDOM.render(
  <Provider store={store}>
    <div id="topNav" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
      <TopNavigation
        className='top-nav'
        i18nStrings={i18nStrings}
        identity={{
          href: "/",
          title: "AWS Marketplace China BPM Portal",
          logo: {
            src: "/awsmplogo-cn.svg",
            alt: "Service"
          }
        }}
      />
    </div>
    <BPMApp />
  </Provider>,
  document.getElementById("root"));