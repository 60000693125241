import React from "react";
import PropTypes from "prop-types";
import {Button, Spinner, TokenGroup, Alert, SpaceBetween, TextContent} from "@amzn/awsui-components-react";
import {SingletonServiceClientBuilder} from "../../../APIs";
import {DOWNLOAD_FILE_SUCCESS} from "../../../common/strings";

export class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.client = SingletonServiceClientBuilder.getClient();
    }

    getFileRealName(name) {
        return name.slice(36, name.length);
    }

    onFileSelect(selected) {
        const {
            onFileSelect = () => {
            }, onUpload = () => {
            }
        } = this.props;
        onFileSelect(selected);
        const selectedFiles = Array.from(selected.target.files);
        onUpload(selectedFiles);
        selected.target.value = null;
    }

    fileUpload() {
        const {disabled = false, loading = false, multiple = false} = this.props;
        if (loading || this.props.widget.value) {
            return null;
        }
        return (
            <div className="awsui-util-pb-s">
                {/* use html5 input feature to read file(s) */}
                <input
                    multiple={multiple}
                    onChange={(selected) => this.onFileSelect(selected)}
                    ref={(c) => (this.inputRef = c)}
                    style={{display: "none"}}
                    type="file"
                />
                <Button variant="normal"
                        formAction="none"
                        iconName="upload"
                        disabled={disabled || loading}
                        onClick={() => this.inputRef.click()}
                >Upload file</Button>
            </div>
        );
    }

    alertRender() {
        let errorMsg;
        if (this.props.widget.errorText) {
            errorMsg = {visible: true, header: 'File Validation Error', tip: this.props.widget.errorText};
        } else {
            errorMsg = this.props.errorMsg;
        }
        return (
            <Alert
                onDismiss={() => errorMsg.visible = false}
                visible={errorMsg.visible}
                dismissAriaLabel="Close alert"
                type="error"
                header={errorMsg.header}
            >
                {errorMsg.tip}
            </Alert>
        )
    }

    getFileWidgetIdForDownload(widget) {
        if (widget.parentId !== undefined) {
            return `${widget.parentId}@${widget.id}`;
        }
        return widget.id;
    }

    downloadFile = (widgetId) => {
        const executionId = window.location.href.split('/').pop();
        this.client.then((result) => {
            result.getDownloadUrl(executionId, widgetId).then((response) => {
                window.open(response.data);
            }, e => {
                console.log(e);
                // TODO: notify user of failed download
            });
        })
    }

    uploadedFileRender() {
        const {
            disabled = false, loading = false, files = [], fileName = ""
        } = this.props;
        // Allow file deselect only if the widget is not locked
        const onFileDeselect = disabled ? () => {} : this.props.onFileDeselect;
        if (files.length === 0 && !loading) {
            return null;
        }
        if (loading) {
            return (<Spinner size="large"/>);
        }
        if (this.props.widget.value) {
            return (
                <TextContent>
                    <TokenGroup
                        alignment="vertical"
                        items={files.map((file) => {
                            return {
                                label: <a
                                    onClick={e => {
                                        e.preventDefault();
                                        this.downloadFile(this.getFileWidgetIdForDownload(this.props.widget));
                                    }}
                                >
                                    {this.getFileRealName(this.props.widget.value)}
                                </a>,
                                iconName: "file",
                            };
                        })}
                        onDismiss={(e) => onFileDeselect(e)}
                    />
                </TextContent>
            );
        }
        return null;
    }

    render() {
        return (
            <SpaceBetween direction="vertical" size="s">
                {this.fileUpload()}
                {this.uploadedFileRender()}
                {this.alertRender()}
            </SpaceBetween>
        );
    }
}

FileUpload.propTypes = {
    onFileDeselect: PropTypes.func,
    onFileSelect: PropTypes.func,
    onUpload: PropTypes.func,
};