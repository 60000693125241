import React from 'react';
import {BaseWorkflow} from "./BaseWorkflow";

export class UpfrontPaymentFlow extends BaseWorkflow {

    paintVertexBasedOnStatus(vertex, nodeMap) {
        const node = nodeMap.get(vertex.id);
        console.log(node);
        if (node === undefined) {
            // Special treatment for PendingCustomerPayment
            return;
        }

        // Regular color scheme
        this.defineRegularStatusColor(node, vertex)
    }

    generateGraphElements(nodeList) {
        const nodeMap = new Map();
        nodeList.forEach(node => {
            nodeMap.set(node.id, node);
        });
        const vertices = [
            {id: 'SystemCreate', data: {label: '账单信息'}, position: this.generateCenterPosition({x: 0, y: 0})},
            {
                id: 'OpsConfirmBilling',
                data: {label: 'Ops确认发送付款通知函'},
                position: this.generateCenterPosition({x: 0, y: 200}),
                className: 'flow-node'
            },
            {
                id: 'PendingCustomerPayment',
                data: {label: '等待客户付款'},
                position: this.generateCenterPosition({x: 0, y: 400})
            }
        ];
        vertices.forEach(vertex => {
            this.paintVertexBasedOnStatus(vertex, nodeMap);
        });
        const edges = [
            {id: 'e-SystemCreate-OpsConfirmBilling', source: 'SystemCreate', target: 'OpsConfirmBilling', type: 'smoothstep'},
            {id: 'e-OpsConfirmBilling-PendingCustomerPayment', source: 'OpsConfirmBilling', target: 'PendingCustomerPayment', type: 'smoothstep'},
        ];
        return [...vertices, ...edges];
    }
}