import React from 'react';
import { Link } from '@amzn/awsui-components-react';
import { renderStatusField } from '../CommonWidgetRenders';
import {renderNewLine } from '../DetailPage/AuditHistoryConfig'

export const wfHrefLink = (wfId) => {
  return "/detail/" + wfId;
}

export const onFollowHandler = (event,history) =>{
    if (!event.detail.external) {
      event.preventDefault();
      const href = event.detail.href      
      history.push(href)
    }
}

export const columnDefinitions = (history) => {
  return [
    {
      id: 'id',
      header: 'Workflow ID',
      cell: item => (
        <div>
          <Link
            href={wfHrefLink(item.id)}
            onFollow={(e) => {
              if (!e.detail.external) {
                e.preventDefault();
                const href = e.detail.href
                history.push(href)
              }
            }}>
            {item.id}
          </Link>
        </div>
      ),
    },
    {
      id: 'workflowType',
      cell: item => item.workflowType,
      header: 'Workflow Type'
    },
    // Currently workflow name 1:1 maps to workflow type, which makes it redundant.
    // In the future this could possibly be implemented as execution name.
    // {
    //   id: 'workflowName',
    //   cell: item => item.workflowName,
    //   header: 'Workflow Name'
    // },
    {
      id: 'assignedGroups',
      cell: item => renderNewLine(item.assignedGroups.replaceAll(",", ", ")),
      header: 'Assigned Group(s): Pending Time'
    },
    {
      id: 'requester',
      cell: item => item.requester,
      header: 'Requester'
    },
    {
      id: 'createdTime',
      header: 'Created Time (UTC+8)',
      cell: item => item.createdTime
    },
    {
      id: 'state',
      header: 'Workflow State',
      cell: item => renderStatusField(item.state)
    }
  ];
}

export const getColumnWithAdditionalHeader = (additionalTitles, type, history) => {
  let columnDefs = columnDefinitions(history);
  //AssignedToMe page will not show additionalTitles columns
  if(type === "AssignedToMe") {
    return columnDefs
  }
  //CasesByType page will show additionalTitles columns
  let additionalTitlesList = [];
  additionalTitles.forEach(header => {
      additionalTitlesList.push({
        id: header,
        cell: item => item.additionalMap[header],
        header: header
      })
    })
  return [...columnDefinitions(history), ...additionalTitlesList]
}
